.delete-confirm-parent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* padding: 4%; */
    width: 40vw;
}
.delete-confirm-parent h2{
    color: #fd0301;
    margin: 5% auto;
    width:fit-content;
}
.delete-confirm-parent div.question-icon-container
{
    border: 4px solid #fd0301;
    padding:4%;
    border-radius: 50%;
}
.delete-dialog-confirm-delete-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6% auto 4%;
}
.delete-dialog-confirm-delete-container button{
    height: 40px;
    width: 120px;
    margin: 4% 2%;
    background-color: transparent;
    font-size: 1rem;
    border-radius: 4px;
    background-color: #fd0301;
    color: #fff;
    border: none;
    cursor: pointer;
}
.delete-dialog-confirm-delete-container button:hover{
    background-color: #db0303;
}
.delete-dialog-confirm-delete-container button.cancel{
    border: 2px solid #fd0301;
    background-color: transparent !important;
    color: #fd0301 !important;
}
.delete-dialog-confirm-delete-container button.cancel:hover{
    background-color: #f8e8e8 !important;
}