.registered-user-header-container{
    background-color: #fff;
    margin: 1%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 1%;
}
.reg-child{
    flex: 30;
    /* padding: 2% 0; */
    /* margin: 2% 0; */
}
.reg-user-name-info-wrapper{
    border-right: 1px solid #eee;
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.reg-user-name-info-wrapper img{
    width: 24%;
    border-radius: 50%;
  
}
.reg-user-name-info-wrapper p.user-name{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 2%;
}
.reg-user-name-info-wrapper p.user-type{
    margin: 2%;
    font-size: 0.9rem;
    font-weight: 500;
}
.reg-user-name-info-wrapper p.status-active{
    background-color: #0bae62;
    border-radius: 4px;
    width: fit-content;
    margin: 2% auto;
    color: #fff;
    padding: 4px 24px;
}

.reg-user-customer-info-wrapper{
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    /* padding:2% 0 !important; */
    height: 16vh;
    justify-content: center;
}
.reg-user-customer-info-wrapper .customer-info-child,.reg-user-package-info-wrapper .package-info-sub{
    display: flex;
    margin: 3% 8%;
  
}
.reg-user-customer-info-wrapper .customer-info-child div.key{
    font-size: 0.9rem;
    flex: 40;
    text-align: left;
}
.reg-user-customer-info-wrapper .customer-info-child div.value{
    flex: 60;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
}
.reg-user-package-info-wrapper{
    /* padding:2% 0 1%; */
    height: 16vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.reg-user-package-info-wrapper .package-info-sub div.key{
    font-size: 0.9rem;
    /* flex: 50; */
    text-align: left;
    width: 60px;
}
.reg-user-package-info-wrapper .package-info-sub div.value{
    flex: 50;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
}
.reg-user-package-info-wrapper .package-info-expiry{
    background-color: #fb0404;
    border-radius: 4px;
    width: 90%;
    height: 32px;
    color: #fff;
    margin: 12% auto 2%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.registered-user-single-table-container{
    background-color: #fff;
    margin: 1%;
    border-radius: 8px;
    padding: 1%;
}
.registered-user-single-table-container h3{
    margin: 2% auto 2% 1%;
    width: fit-content;
}


/* new design */
.single-user-bottom-container{
    display: flex;
    flex-direction: column;
}
.single-user-first-container{
    display: flex;
    flex-wrap: wrap;
}
.single-user-top-single-card{
    padding: 1%;
    width: 46%;
    background-color: #fff;
    margin: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid #ffff;
    cursor: pointer;
}
.single-user-top-single-card-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3e3e6;
}
.single-user-top-single-card-image img{
    width: 50%;
}
.single-user-top-single-card p{
   font-size: 0.75rem;
   font-weight: 500;
   margin-left: 8px;
}

.single-user-second-container{
    display: flex;
    flex-direction: column;
    margin: 1%;
}
.package-label{
    text-align: left;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #d3d3d3;
    padding: 8px 14px;
    display: flex;
}
.go-to-website{
    background: #e51448;
    color: #fff;
    width: 40px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .go-to-website p{
    font-size: 0.55rem;
    font-weight: 600;
  }
  .reset-password-input{
    display: flex;
    border: 1px solid #8a8888;
    height: 30px;
    align-items: center;

    background-color: #e2e1e1;
    margin: 7% 0;
  }
  .reset-password-input p{
    font-size: 0.7rem;
    font-weight: 600;
    background-color: #e2e1e1;
  
    width: 35%;
    margin-left: 12px;
  }
  .reset-password-input input{
    border: none;
    height: 28px;
    width: 65%;
    background-color: #fff;
  }
  .reset-submit{
    background-color: #33b166;
    border: none;
    height: 40px;
    width: 100%;
    color: #fff;
  }
  .reset-top-container{
    display: flex;
    align-items: center;
  }
  .reset-top-container p{
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0 0 0 8px;
  }
  .reset-top-container-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid #33b166;
    border-radius: 50%;
  }
  .pay-balance-container{
     display: flex;
     flex-direction: column;
     margin: 8% 0;
  }
  .pay-balance-container p{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 1% 0;
  }
  .pay-balance-container input{
    border-radius: 4px;
    height: 35px;
    border: 1px solid #aaa8a8;
  }
