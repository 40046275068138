.status-span{
    padding: 5px 15px;
    border-radius: 16px;
    font-size: 0.7rem;
}
.pending{
    background-color: #ff870d5c;
    color: #ff8d00;
}
.accepted{
    background-color: #b8deb1;
    color: #00bb0f;
}
.rejected{
    background-color: #deb1b1;
    color: #c60000;
}

.emp-leave-detail-top-ctn{
    width: 50%;
    display: flex;
    justify-content: space-between;
}
.emp-leave-detail-card{
    border-radius: 4px;
    font-size: 0.9rem;
    color: white;
    width: 32%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.emp-leave-apply-button{
    width: 20%!important;
    margin-right: 0!important;
}

.leave-application-view-container{
    background-color: #fff;
    width: 40vw;
}
.leave-application-view-section{
    padding: 3% 6%;
    display: flex;
    flex-direction: column;
}
.leave-application-view-container-top{
    padding: 2% 0;
    border-bottom: 2px solid rgba(44, 44, 44, 0.8);
}
.leave-application-view-container-body{
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(65, 65, 65, 0.8);
    padding: 2%;
    margin: 3% 0;
}
.leave-application-view-container-button{
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    width: 45%;
    padding: 2% 0;
}
.leave-application-view-container-button .btn{
    width: 45%;
}
.leave-application-view-container-button .btn-green{
    background-color: #05a84c;
}
.leave-application-view-container-button .btn-green:hover{
    background-color: #007e37;
}

.leave-application-view-container-body p{
    line-height: 1.2rem;
}

.apply-leave-section{
    padding: 3% 5%;
}
.apply-leave-top-para{
    font-weight: 600;
}
.apply-leave-top-para span{
    color: red;
}
.apply-leave-section .global-single-input{
    width: 100%;
    margin: 3% 0;
}
.apply-leave-section .global-top-container-head-section{
    margin: 3% 0;
}
.apply-leave-btn-ctn{
    display: flex;
    justify-content: center;
}