.middle-left-container{
    display: flex;
    flex-direction: column;
    flex:65%;
    background-color: #fff;
    margin: 0 1% 0 0;
    padding-left:6px;
    padding-right:4px;
 
    
}
.middle-left-container p{
  align-self: flex-start;
  font-size:0.9rem;
  font-weight:500;
  margin: 3% 0 2% 6px;
}
.middle-right-container{
    flex:35%;
    /* background-color: #fff; */
}
/* .recent-purchase-status{
    padding: 8px 0 !important;
} */

.recent-purchase-status p.active{
    background-color:#00ac28;
    color:#fff;
    padding:3px 8px;
    border-radius:12px;
    font-size: 0.7rem;
   
    margin:0 auto;
}
.recent-purchase-status p.renewal{
   
    background-color: #e60707;
    color:#fff;
    padding:3px 8px;
    border-radius:12px;
    font-size: 0.7rem;
    margin:0 auto;
    
}
/* right container */
.middle-right-top-container{
    height:40vh;
    background-color:#fff;
    margin-bottom:8px;
    display:flex;
    flex-direction:column
}
.middle-right-bottom-container{
    height:40vh;
    background-color:#fff;
    display:flex;
    flex-direction:column
}
.right-top-head{
    align-self: flex-start;
    font-size:0.9rem;
    font-weight:500;
    margin: 4% 0 2% 12px;
}
.chart-container{
    margin: 0 auto; 
    max-width: 100%;
    position: relative; 
    /* width: 360px; */
    height:59%;
}
.middle-right-bottom-first-container{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin:6%;
}
.middle-right-bottom-first-container p{
    align-self: flex-start;
    font-size:0.9rem;
    font-weight:500;
}
.edit-icon-container{
    border:2px solid #8373f1;
    width:40px;
    height:20px;
    border-radius:4px;
    cursor:pointer;
}
/* quick access */

.quickAccess-container{
    background-color: #d0ebef;
    margin:2% 6%;
    height:40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between ;
    align-items: center;
    border-radius: 4px;
}
.quickAccess-container p{
    font-size:0.9rem;
    font-weight: 600;
    line-height: 1.6;
}
.quickAccess-content{
    display: flex;
    flex-direction: row;
    width:80% ;
    margin: 0 8%;
    justify-content: space-between;
}
.purchase-table{
    max-height:91.5vh !important;

}



.quick-access-dialog-container{
    width: 590px;
    height: 400px;
    display: flex;
    flex-direction: column;
    padding: 4%;
    
}



.quick-access-dialog-container h3{
    font-size: 20px;
    margin-bottom: 1rem;

}

.quick-access-dialog-select{
    display: flex;
    flex-direction: column;
}

/* .QuickAccess-dialog-box{
    border: 2px solid orange;
    width: 100%;
    height: 100%;
} */

/* .QuickAccess-dialog-box{
    border: 2px solid rgb(112, 214, 10);
    width: 1200px;
    height: 1200px;
} */

.quick-access-input{
    /* border: 2px solid rgb(10, 99, 223); */
    width: 90%;
    /* overflow: hidden; */;
    margin: 2%;
}


.quick-access-edit-button{
    /* border: 2px solid orange; */
    width: 90%;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    cursor: pointer;
}

.quick-access-edit-cancel-button{
   
    padding: 10px 50px;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    border: 2px solid black;
    color: black;
}

.quick-access-edit-submit-button{
 
    padding: 10px 50px;
    border: none;
    font-size: 1rem;
    background-color: blue;
    color: white;
    margin-left: 1rem;
    cursor: pointer;
    border-radius: 5px;
}
