.left-menu-parent-container {
  /* min-width: 10vw; */
  width: inherit;
  height: 100%;
  background-color: #8373f1;
  padding: 1px;
  padding-right: 0;
  /* position: relative;
  bottom: 20px; */
  overflow-y: scroll;
}
.left-menu-parent-container::-webkit-scrollbar{
  width: 2px;
}
.left-menu-parent-container::-webkit-scrollbar-thumb{
  background-color:#3a1ed6
}
/* Pofile Info  */
.profile-info-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2%;
  height: 60px;
}
.profile-info-container img.avatar-img {
  margin: 0;
  margin-right: 4%;
  width: 14%;
  border-radius: 50%;
}
.profile-info-container p {
  font-size: 1.2rem;
  font-weight: 700;
}

/* Left Menu Info Collapsed Style  */
.left-menu-collapsed .profile-info-container img.avatar-img{
    width: 45%;
}

.left-menu-collapsed .profile-info-container p{
  display: none;
}



/* Navigation Menu  */

.left-menu-parent-container ul {
  list-style: none;
  padding: 2% 0 0 8%;
}
.left-menu-parent-container ul li {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: left;
  /* padding: 4% 0 4% 1%; */
  margin: 3% 0;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left-menu-parent-container ul li:hover, .focused {
  background-color: #fff;
  color: #6d83bc;
}
.left-menu-parent-container ul li:focus-within,.left-menu-parent-container ul li:active {
  background-color: #fff;
}
.left-menu-parent-container ul li:hover svg, .left-menu-parent-container ul li:focus-within svg{
  fill: #8373f1;
}

.left-menu-parent-container ul li svg{
  width: 26px;
  height: 26px;
  margin-right: 3%;
  fill: #fff;
}

.left-menu-parent-container ul li img {
  width: 8%;
  margin: 1% 3% 1% 1%;
  padding: 2%;
}
.left-menu-parent-container ul li a {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  padding: 4%;
  color: #fff;
  text-decoration: none;
  border-radius: 4px 0 0 4px;
}
.icon-tool-tip-container{
  justify-content: space-between;
}
.left-menu-parent-container ul li a.active svg{
  fill: #8373f1;
}
.left-menu-parent-container ul li a:hover, .left-menu-parent-container ul li a.active {
  color: #6d83bc;
  background-color: #fff;
 
}

.left-menu-parent-container .left-menu-tool-tip{
  /* border: 2px solid orange; */
}
.showOffer{

  border: 2px solid orange;
  background-color: blue;
  display: none;
}
/* Collapsed State Menu Label  */

.left-menu-collapsed ul li a span.menu-label{
  display: none !important
}
.left-menu-collapsed ul li{
  margin: 16px 0;
  padding: 4px 0;
}
.left-menu-collapsed ul li a{
  align-items: center ;
  justify-content: center;
}
.left-menu-collapsed ul li a svg{
  width: 28px;
  height: 28px;
}
.left-menu-collapsed ul li.hidden-li{
  display: none;
}