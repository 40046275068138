.report-main-container{
    display: flex;
}
.report-parent-container{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgb(224, 223, 223);
    margin:1%;
    min-width: 15vw;
    max-width: 25vw;
    width: 20vw;
    padding: 2% 1%;
}

.report-menu-input-container p{
    font-size: 0.78rem;
    font-weight: 600;
    margin-bottom: 8px;
    width: fit-content;
    margin-right: auto;
}
.report-menu-input-container div.input-wrapper{
    display: flex;
    justify-content: space-between;
}
.report-menu-input-container div.input-wrapper div.mobile-no-container{
    display: flex;
    border: 1px solid #eee;
    width: 80%;
}
div.input-wrapper div.mobile-no-container input{
    width: 85%;
    height: 32px;
    border: none;
    padding-left: 8px;
}
div.input-wrapper div.mobile-no-container input.country-code{
    width: 15%;
    border: none;
    border-right: 1px dashed #eee;
   
}
div.input-wrapper div.mobile-no-container input.email-input{
    width: 100% !important;
}
.report-menu-input-container div.input-wrapper button.add-btn{
    width: 48px;
    background-color: #000;
    color: #fff;
    font-size: 1.4rem;
    margin: 0 1%;
    cursor: pointer;
}
.report-menu-input-container div.input-wrapper button.add-btn:hover{
    background-color: rgb(32, 32, 32);

}
.report-parent-container .display-container{
    height: 85%;
}
.report-parent-container div.data-row p{
    width: fit-content;
    font-size: 0.8rem;
    font-weight: 500;
    margin-left: 8px;
}
.report-parent-container div.data-row{
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



/* Reports Page  */
.report-parent-container .report-radio-group{
    display: flex;
    justify-content: space-between;
}

.report-parent-container .report-radio-group input[type='radio']{
    accent-color: #000;
    cursor: pointer;
}
.report-parent-container .report-radio-group label{
    margin-left: 8px;
}
.report-parent-container .report-checkbox-group div.reports{
    margin: 6% 0;
    display: flex;
}
.report-parent-container .report-checkbox-group div.reports input[type='checkbox']{
accent-color: #000;
cursor: pointer;
}
.report-parent-container .report-checkbox-group div.reports label{
cursor: pointer;
margin-left: 8px;
font-weight: 400;
}