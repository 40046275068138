.total-staff-container{
    background-color: #ebe9f7;
    width: 15%;
    display: flex;
    justify-content: space-between;
    padding: 1% 2%;
    align-items: center;
    color: #5b44f3;
    border-radius: 4px;
    margin: 1% 0 0 2%;
}
.total-staff-left-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.total-staff-right-container{
    background-color: #8373f1;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.total-staff-right-container img{
    width: 35px;
}
.attendance-month-input input{
   background-color: #ebe9f7 !important;
}
.attendance-active-status{
    background-color: #d4e3d3;
    color: #40a534;
    padding: 7% 21%;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 4px;
}