
.employee-dashboard-card-container1{
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
}

.employee-dashboard-card1{
    background-color: #fff;
    padding: 2% 3%;
    width: 26%;
    margin: 1% 2% 1% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
  
}

.employee-dashboard-card-container1 >:nth-child(3){
    margin-right: 0;
}