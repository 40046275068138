.add-offer-container{
    background-color: #fff;
    border-radius: 8px;
    width: 70%;
    box-shadow: 1px 0 4px #ddd;
    padding: 2%;
    margin: 1%;
}
.add-offer-container h3{
    margin:1% auto 2% 1%;
    width: fit-content;
}
.add-offer-container hr{
    border: none;
    border-bottom: 2px solid #eee;
    margin: 1% 0;
}
.add-offer-container .add-offer-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2%;
}
.add-offer-container .add-offer-single-input{
    width: 40% !important;
}
.add-offer-cancel-submit-container{
    display: flex;
    justify-content: center;
    align-items: center;
}