.daily-report-parent-container h3 ,.daily-report-list-table-container h3{ 
    margin: 1% auto 1% 1%;
    width: fit-content;
}
.daily-report-parent-container hr{
    border: none;
    border-bottom: 1px solid #eee;
}
.daily-report-form-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}
.daily-report-form-container .daily-report-form-child{
    display: flex;
    justify-content: space-between;
}
.daily-report-form-container .daily-report-form-child .fullwidth-input{
    width: 10%;   
    margin: 4% auto;
}

.daily-report-submit-container{
    width: 10%;   
    margin: 4% auto;
    display: flex;
    align-items: center;
}
.daily-report-button-contained{
        height: 40px;
        background-color: #8373f1;
        width: 100%;
        border: none;
        border-radius: 4px;
        font-size: 0.9rem;
        cursor: pointer;
        color: #fff;       
}

.refund-status-ctn{
    display: flex;
    width: 100%;
    height: 30px;
    justify-content: center;
    color: #48aa54;
    align-items: center;
    background-color: #00d5001f;
}
.refund-status-ctn p{
    padding: 2%;
}