.login-container{
  display: flex;
  flex-direction: row;
  width:100%;
  height: 100vh;
}
.login-left-container{
    width: 50%;
    /* height: 100vh; */
    background-color:#fff
}
.login-right-container{
    width: 50%;
    background-color:#eef6ff
    /* height: 100vh; */
}