.customer-list-single-view-container{
    background-color: #fff;
    padding :1% 0;
    width: 45vw;
}
.customer-list-single-view-sub-container{
    padding: 3%;
}
.customer-list-single-view-input-container{
    display: flex;
    flex-wrap: wrap;
}