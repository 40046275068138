.register-customer-container-first{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* second container */
.register-customer-container-second{
    display: flex;
   justify-content: center;
   align-items: center;
   
}
.register-customer-container-second-radio{
    background-color: #eee;
    width: 200px;
    height: 26px;
    display: flex;
    padding: 1%;
    border-radius: 4px;
    margin: 2% 0 0 0;
}
.register-customer-container-second-radio-single{
   width:50%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #eee;
   color: #000;
   font-size: 0.8rem;
   font-weight: 600;
   cursor: pointer;
   border-radius: 4px;
}
.select{
    background-color: #2626f5;
    color: #fff;
}

/* third container */
.register-customer-container-third{
    display: flex;
    justify-content: center;
}
.register-customer-container-third-single{
    width: 310px;
    height: 340px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 12px; 
    margin: 4%;
}
.register-customer-container-third-single-top{
    display: flex;
    flex-direction: column;
    padding: 7%;
    background-color: rgb(207, 6, 157); /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, rgb(251 139 103) , rgb(247 12 169 / 85%));
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height:53px
}
.register-customer-card-std{
    color: #fff;
    font-size: 0.85rem;
    margin: 1%;
}
.register-customer-card-std-price{
    color: #fff;
    font-size: 0.95rem;
    margin: 1%;
    font-weight: 600;
}
.register-customer-container-third-single-bottom{
    display: flex;
    flex-direction: column;
    padding: 4%;
    height: 66%;
    align-items: center;
}
.register-customer-card-icon-label{
    display: flex;
    align-items: center;
    margin: 3%;
    width: 228px;
}
.register-customer-card-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(159, 159, 255);
    width: 20px;
    height: 20px;
}
.register-customer-card-icon-label p{
    font-size: 0.8rem;
    font-weight: 600;
    margin-left:18px;
}
.register-customer-container-third-single-bottom button{
    background-color: #2626f5;
    color: #fff;
    width: 80%;
    height: 34px;
    border-radius: 4px;
    border: none;
    margin: 10% 0 0 0;
    cursor: pointer;
}
.platinum-card{
    height: 500px;
}
.platinum-card-top{
    background-color: red; /* For browsers that do not support gradients */
    background-image: linear-gradient(to right, rgb(235, 195, 19), red);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.platinum-card-bottom{
    height: 77%;
}

/* register form dialog */
.register-form-container{
 
    width:30vw;
  display: flex;
  flex-direction: column;
  
}
.register-form-container-new{
    padding:10% 6%;
}
.register-form-input{
    display: flex;
    flex-direction: column;
    width: 97%;
    margin: 5% 1%;
}
.register-form-input p{
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 6px;
}
.register-form-input input{
    width: 99%;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #575656
}
.create-mobile-package-link{
    color: #8373f1;
    font-size: 0.8rem;
    font-weight: 600;
    border-bottom: 1px solid #8373f1;
    margin: 0 2%;
    cursor: pointer;
}

.customer-reg-popup{
    background-color: #fff;
    border-radius: 14px;
    padding: 3%;
    box-shadow: 0 0 10px 0px rgb(179 174 174 / 50%);
    width: 50%;
    margin: 8% auto;
    height: 46%;
}


.cart-main-container{
    height: 90vh;
}
.cart-container{
    display: flex;
    justify-content: space-between;
    margin:1% auto;
    /* background-color: #d3d7f7; */
    width: 90%;
}


.cart-right-single-container{
   
    border-radius: 4px;
    margin: 2%;
    padding: 2% 4%;
}
.cart-right-single-first-container{
    display: flex;
    justify-content: end;
}
.close-icon-container{
    width: 30px;
    height: 30px;
}
.close-icon{
    font-size:1.5rem;

}
.cart-right-single-second-container{
    display: flex;

}
.cart-right-single-second-icon-container{
    background-color: #ff00cc;
    width: 38px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: #ff00cc 0px 0px 24px -10px;
    padding: 2%;
}
.cart-right-single-second-heading-container{
    margin-left: 8%;
    width: 45%;
}
.cart-right-single-second-heading-container p{
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(3, 187, 3);
}
.cart-right-single-second-amount-container{
   display: flex;
   width: 40%;
    justify-content: flex-end;
   
}

.cart-right-container{
   
    width: 37%;
}

.cart-right-top-container{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
    padding: 2%;
    min-height: 40vh;
    
}
.cart-right-top-single-container{
    background-color: #f9f7f7;
    padding: 2%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
}
.cart-right-top-single-one-container{
    display: flex;
    width: 100%;
   margin: 2% 0;
}
.cart-plus-btn{
    border: 1px solid blue;
    color: blue;
    width: 24px;
    height: 24px;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cart-right-bottom-container{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
    padding: 4% 8%;
    margin-top: 4px;
    min-height: 25vh;
}
.cart-right-bottom-single-container{
    display: flex;
    justify-content: space-between;
    margin: 4% 0;
}
.cart-right-item-container{
    max-height: 53vh;
    overflow-y: auto;
}
.cart-right-item-container::-webkit-scrollbar{
  width: 4px;
  background-color: #f1f1f1;
}
.cart-right-item-container::-webkit-scrollbar-thumb{
    background-color: #c1c1c1;
  }
.cart-right-single-third-container{
    display: flex;
    flex-direction: column;
    margin: 2% 0;
}
.cart-right-modules-container{
    width: 90%;
    align-self:end;
    margin: 2% 0;
}
.cart-right-single-modules-container{
    display: flex;
    justify-content: space-between;
}
.cart-right-sms-package-container{
    display: flex;
    justify-content: space-between;
    margin: 4% 0;
}
.cart-right-single-fourth-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart-right-single-button-container{
    display: flex;
    justify-content: space-between;
    width: 30%;
    align-items: center;
}
.cart-right-single-button-container button{
    width: 35px;
    height: 30px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cart-right-sub-total-conatiner{
    display: flex;
    justify-content: space-between;
    margin: 3%;
}
.cart-sub-total{
    font-size: 1.2rem;
    color: #8b8686;
    font-weight: 600;
}
.cart-sub-total-amt{
    color:  #fe0d0d;
    font-size: 1.2rem;
    font-weight: 600;
}
.cart-tax-amt{
    color: #000;
    font-size: 1.2rem;
    font-weight: 600;
}
.cart-buy-now-button{
    margin: 3%;
    background-color: #004eff;
    color: #fff;
    border: none;
    width: 94%;
    height: 50px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
}
.cart-free-trial-button{
    margin: 3%;
    background-color: #fff;
    color: #004eff;
    border: dashed 2px;
    width: 94%;
    height: 50px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
}
.cart-free-trial-button-disable{
    cursor: not-allowed;
    margin: 3%;
    background-color: #fff;
    color: #ddd8d8;
    border: dashed 2px;
    width: 94%;
    height: 50px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 4px;
}
.cart-footer-text{
    margin: 0% 9%;
    text-align: center;
}
.cart-footer-text p{
    font-size: 0.9rem;
    color: #8b8b8b;
}

/* left container */
.cart-left-container{
    width: 57%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0px rgb(212 207 207 / 50%);
    padding: 2%;
    background-color: #fff;
    border-radius: 10px;
   
}
.cart-left-item-container{
    max-height: 85vh;
    overflow-y: auto;
    min-height: 65vh;
}
.cart-left-item-container::-webkit-scrollbar{
    width: 4px;
  background-color: #f1f1f1;
}
.cart-left-item-container::-webkit-scrollbar-thumb{
    background-color: #c1c1c1;
}
.cart-left-item-single-container{
    background-color: #fff;
    border-radius: 4px;
    padding:2% 1%;
    margin: 1% 0;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #aaa8a8;

}
.cart-left-item-single-checkbox-container{
    width: 20px;
    margin-right: 30px;
}
.cart-left-item-single-container input[type="checkbox"]{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.cart-left-item-single-image-label-container{
    display: flex;
    width: 50%;
    
}
.cart-left-item-single-image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eae9f6;
}
.cart-left-item-single-image img{
    width: 60%;
}

.cart-left-item-bottom-container{
    background-color: #eee;
    padding: 2%;
    border-radius: 6px;
    display: flex;
    height: 9vh;
    align-items: center;
}
.cart-input-sms input{
    width: 93%;
    background-color: #fff;
    height: 30px;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
    padding: 4px 11px;
}
.cart-input-sms input:focus {
    outline: none;
  }
  .cart-input-sms div.MuiOutlinedInput-root   {
    width: 100%;
    border: none;
   
  }

.cart-left-item-single-top-container{
    background-color:#f9f9f9;
    padding: 1% 4%;
    margin: 1%;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.cart-left-icon-container{
    width: 25px;
    height: 25px;
    
}
.cart-left-top-text-container{
    width: 40%;
    margin-left: 5%;
    display: flex;
    align-items: center;
}
.cart-left-menu-conatiner{
    display: flex;
    width: 48%;
    justify-content: space-evenly;
    align-items: center;
   
}
.cart-left-single-menu-conatiner{
    padding: 16px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 600;
    color: #8b8b8b;
}
/* .cart-left-single-menu-conatiner p{
    font-size: 1.2rem;
    font-weight: 600;
    color: #8b8b8b;
   
} */
.active-btn{
    color: red !important;
    border-bottom: 4px solid red;
}
.card-left-second-container{
    display: flex;
    flex-direction: column;
    margin: 2% 4%;
}
.card-left-second-container h2{
    color: #03dd62;
    
}
.card-left-checkbox-modules-container{
    display: flex;
    /* justify-content: space-between; */
}
.card-left-checkbox-modules-single-container{
    background-color: #f9f9f9;
    padding: 3%;
    margin: 1%;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 92%;
    height: 60px;
}
.cart-left-checkbox-container input{
    width: 25px;
    height: 25px;
    accent-color: #ff0000;
    cursor: pointer;
}
.cart-left-checkbox-head-container{
    display: flex;
    flex-direction: column;
}
.cart-left-main-head{
    font-size: 1.4rem;
    color: #ff0000;
    font-weight: 700;
}
.cart-left-sub-head{
    font-size: 1rem;
    font-weight: 700;
}
.card-left-third-container{
    background-color: #fff;
    box-shadow: #a5a3a3 10px 1px 24px -10px;
    padding: 3%;
    margin: 4% 10%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}
.card-left-third-enable-sms-package-container{
    display: flex;
    width: 35%;
    /* justify-content: space-between; */
    align-items: center;
}
.card-left-third-enable-sms-package-container input{
    cursor: pointer;
    width: 18px;
    height: 18px;
    accent-color:#1100ff ;
}
.card-left-third-enable-sms-package-container label{
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 8px;

}
.global-single-input {
    position: relative;
    width: 45%;
    margin: 1%;
    display: flex;
    flex-direction: column;
  }
  .global-single-input input::placeholder {
    font-size: 0.9rem;
  }
  .global-single-input div.MuiOutlinedInput-root {
    width: 100%;
    border: none;
    background-color: #eee;
    height: 40px;
  }
  .auto-complete-cart div.MuiOutlinedInput-root{
    height: auto !important;
  }
  .auto-complete-cart .MuiAutocomplete-input{
    height: 7px;
  }
  .global-single-input
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 8px !important;
  }
  .global-single-input div fieldset {
    border: none;
    border-radius: 0;
  }
  .global-single-input input{
    width: 100%;
    border: none;
    background-color: #eee;
    height: 40px;
    border-radius: 4px;
  }

  .empty-cart-container{
     display: flex;
     justify-content: center;
     align-items: center; 
     margin: 4% 2%;
  }
  .empty-cart-container img{
    width: 20%;
  }

  .buy-now-button-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(21, 74, 247);
    border: none;
    height: 40px;
    color: #fff;
    border-radius: 4px;
    width: 50%;
    box-shadow: 0 0 10px 0px rgb(21, 74, 247);
}
.free-trial-cart{
    height: 40px;
    width: 50%;
    border-radius: 4px;
    border: 1px dashed rgb(21, 74, 247);
    background-color: #fff;
    color: rgb(21, 74, 247);
    
}

.cart-input-sms input{
    width: 93%;
    background-color: #fff;
    height: 30px;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
    padding: 4px 11px;
}
.cart-input-sms input:focus {
    outline: none;
  }
  .cart-input-sms div.MuiOutlinedInput-root   {
    width: 100%;
    border: none;
   
  }


  @media(max-width:720px){
    .cart-container{
        flex-direction:column ;
    }
    .cart-left-container{
        width: 100%;
    }
    .cart-left-item-container{
        max-height: 69vh;
    }
    .cart-left-top-text-container h2{
        font-size: 1rem;
    }
    .cart-left-checkbox-container input{
        width: 17px;
        height:17px;
    }
    .cart-left-main-head{
        font-size: 1rem;
    }
    .cart-left-sub-head{
        font-size: 0.8rem;
    }
    .card-left-third-enable-sms-package-container label{
        font-size: 0.8rem;
        margin-left: 7%;

    }
    .cart-right-container{
        width: 100%;
    }
  }