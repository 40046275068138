.forward-menu-parent{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgb(224, 223, 223);
    margin:1%;
    min-width: 15vw;
    max-width: 30vw;
    width: 25vw;
}
.forward-menu-parent ul{
    width: 100%;
    padding: 0;
    list-style: none;
}
.forward-menu-parent ul li{
    display: flex;
    align-items: center;
    width: 80%;
    height: 32px;
    padding: 2% 10%;
    margin: 2% 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 0.9rem;
    text-align: left;
}
.forward-menu-parent ul li:hover{
    color: #8373f1;
    background-color: #ebeefe;
}
.forward-menu-parent ul li:hover input{
    accent-color: #8373f1;
}
.forward-menu-parent ul li div.checkbox-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
} 
.forward-menu-parent ul li div.checkbox-container .checkbox-single-grp{
display: flex;
align-items: center;
}  
.forward-menu-parent ul li div.checkbox-container .checkbox-single-grp label{
    font-size: 0.8rem;
    margin-left: 4px;
}
.forward-menu-parent ul li div.checkbox-container .checkbox-single-grp input[type='checkbox']{
    accent-color: #8373f1;
}

.active-menu{
    background-color: #ebeefe;
    color: #8373f1;
}