.admin-invoice-container{
    width: 100%;
    height: auto;
}
.admin-padding-invoice-container{
    padding: 2% 3% ;
}
.justify-space-between{
    display: flex;
    justify-content: space-between;
    margin: 1%;
}
.address-eng-container{
    width: 48%;
}
.address-eng-container p{
    font-size: 0.8rem;
    margin: 4px 0;
}
.address-arabic-container{
    width: 48%;
    display: flex;
    flex-direction: column;
    text-align: right;
}
.invoice-table-container{
    /* margin-top: 1%; */
    /* max-height: 75vh;
    overflow-y: scroll; */
    padding: 0 2%;
}
.invoice-table-container table{
    width: 100%;
    margin-top: 1%;
    border-collapse: collapse;
}
.invoice-table-container table thead tr th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #878585;
    font-size: 0.84rem;
    font-weight: 500;
    height: 30px;
    color: #fff;
  }
  .invoice-table-container table tbody tr td {
    text-align: center;
    background-color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    /* padding: 12px 12px; */
    /* border-bottom: 1px solid #eee; */
  }
  .sms-whatsapp-package table thead tr th{
    background-color: #c5c4c4 !important;
    text-align: left !important;
    border-top: 1px solid #b6b4b4;
    border-left: 1px solid #b6b4b4;
    border-right: 1px solid #b6b4b4;
  }
  .sms-whatsapp-package table tbody tr td{
    text-align: left !important;
    border-bottom: 1px solid #b6b4b4;
    border-left: 1px solid #b6b4b4;
    border-right: 1px solid #b6b4b4;
    height: 30px;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0 4px;
  }
  .backup-price{
    background-color: #aaaaaa;
    display: flex;
    justify-content: space-between;
    padding: 8px 8px;
    margin: 2%;
    color: #000;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .invoice-sub-total-container{
    display: flex;
    flex-direction: column;
    align-items: end;
    margin: 8px 0
  }
  .invoice-sub-total-container p{
    font-size: 0.7rem;
    font-weight: 600;
    margin: 4px 18px 
  }
  .invoice-autorized-signatory{
    display: flex;
    flex-direction: column;
    align-items: end;
    margin: 8px 0
  }
  .invoice-autorized-signatory p{
    font-size: 0.7rem;
    font-weight: 600;
    margin: 4px 50px 
  }
  .invoice-thank-you{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .invoice-thank-you{
    font-size: 0.9rem;
    font-weight: 600;
    margin: 4%;
  }
  .invoice-terms-and-conditions{
    display:flex;
    flex-direction: column;
}
.invoice-terms-and-conditions-eng h5{
   margin: 4px 0;
}
.invoice-terms-and-conditions-eng p{
    margin: 4px 0;
    font-size: 0.7rem;
   
    color: #999696;
 }
 .invoice-terms-and-conditions-arabic{
    display: flex;
    flex-direction: column;
    text-align: right;
   
 }
 .invoice-terms-and-conditions-arabic p{
    margin: 4px 0;
    font-size: 0.7rem;
   
    color: #999696;
 }