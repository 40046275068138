.department-container {
    background-color: #fff;
    padding: 2%;
  }
  .department-top-container {
    display: flex;
    justify-content: space-between;
    margin: 0 1%;
  }
  .department-top-container h3 {
    margin: 1% 0 0 0;
  }
  .department-top-container button {
    background-color: #0ad597;
    border: none;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    height: 40px;
    width: 15%;
    border-radius: 4px;
  }
  .department-top-container button:hover {
    background-color: #06be87;
    cursor: pointer;
  }
  .add-department-dialog-container {
    width: 40vw;
   
  }
  .add-department-dialog-sub-container{
    padding: 4% 6%;
  }
  .add-department-dialog-container h3 {
    margin: 1% 0;
  }
  .department-input {
    position: relative;
    width: 99% !important;
    margin: 4% 0;
  }
  .department-input input {
    height: 55px !important;
  }
  
  .department-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6% 0 2% 0;
  }
  .department-button-container button {
    width: 24% !important;
  }
  
  /* designation css */
  .designation-button {
    background-color: #d5340a !important;
  }
  .designation-button:hover {
    background-color: #e63104 !important;
  }
  

  .global-page-parent-container1{
    display: flex;
    flex-direction: column;
    
  }