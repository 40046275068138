.package-plans-parent h3{
    width: fit-content;
    margin: 1% auto 2% 1%;

}
.package-plans-parent hr{
    border: none;
    border-bottom: 1px solid #eee;
}
.package-plans-parent .add-package-form{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2%;
}
.package-plans-parent .package-plans-input{
    width: 30% !important;
}
.package-module-add-btn button{
    position: absolute;
    top: 100%;
    left: 0%;
    right: 0%;
    border: none;
    cursor: pointer;
    background-color: transparent;
  
    color: #8373f1;
    font-weight: 600;
    display: flex;
    justify-content: end;
    font-size: 0.7rem;
}
.package-module-add-btn button:hover{
    color: #715ee9;
}
.add-color-container{
    position: relative;
    width: 100%;
}
.add-color-container input[type="color"]{
    padding: 0;
    width: 37px;
    position: absolute;
    top: 10%;
    right: 2%;
    height: 37px;
    cursor: pointer;
}

.upload-image-input{
    position: relative;
    width: 100%;
}
.upload-image-input input[type="file"]{
    opacity: 0;
}
.upload-image-input label{
    position: absolute;
    right: 9px;
    top: 10px;
    background-color: blue;
    width: 40%;
    height: 28px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-Size:0.8rem;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
}
.upload-image-input label img{
    width: 14%;
}


/* content css */
.content-icon-container{
    display: flex;
    width: 60%;
    margin: 2%;
    justify-content: space-between;
}
.content-icon-single{
    
    display: flex;
    margin: 0 1%;
}
.content-icon-single input[type="radio"]{
    cursor: pointer;
}
.content-icon-single label{
    cursor: pointer;
}
.type-1-content{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #FBC9BD;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
}
.type-1-content i{
    color: #F2492C;
}
.type-2-content{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #B8C3FB;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px; 
}
.type-2-content i{
    color: #092EF2; 
}
.check-circle{
    margin-left: 8px;
    font-size: 1.4rem;
}
.star-fill{
    color:rgb(240, 216, 6);
    margin-left: 8px;
    font-size: 1.4rem;
}
.check2{
    margin-left: 8px;
    font-size: 1.4rem;
    color: #000;
}
