.login-right-main-container{
    display:flex;
    flex-direction: column;
    height:100vh;
}
.login-right-top-container{
    display: flex;
    flex:50%;
    background-image:url('../../Assets/Images/Image\ 2.png');
    background-position: 100% 10%;
    /* transform: translate(0px,0px);  */
    background-repeat: no-repeat;
    background-size:95% 150%;
    
    
  
}
.login-right-bottom-container{
    display: flex;
    flex: 50%;
    background-image: url('../../Assets/Images/Image\ 3.png');
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-position: 0 170%;
    background-size:95% 75%;
   
}
.right-bottom-image-container{
    display: flex;
    justify-content: flex-end;
    width:100% ;

}
.right-bottom-image{
    width:60%;
   
}