.staff-add-payroll-container{
    width: 60vw;
    margin: 1%;
}
.staff-add-payroll-top-ctn{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 3%;
    text-align: start;
    width: 94%;
}
.staff-add-payroll-top-ctn h3{
    margin: 1%;
}
.staff-add-payroll-top-ctn-input-field{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2% 0;
}
.add-payroll-input{
    flex-basis: 30%;
}
.staff-add-payroll-top-ctn-btn-ctn{
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* margin:2%; */
}
.add-payroll-body-earning-p{
    color: #05b761;
    font-weight: 600;
}
.staff-add-payroll-body-ctn{
    display: flex;
    margin-top: 2%;
    text-align: start;
    width: 100%;
    justify-content: space-between;
}
.staff-add-payroll-body-container-parent{
    width: 49%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.staff-add-payroll-body-section-input-field input{
    text-align: right;
    padding: 2% 20%;
}
.staff-add-payroll-body-section{
    width: 95%;
    background-color: #fff;
    padding: 3% 0 0 5%;

}
.staff-add-payroll-body-section h4,.staff-add-payroll-body-section p{
    margin: 2% 0;
}
.staff-add-payroll-body-section-input-field .global-single-input{
    width: 100%!important;
    margin: 3% 0 !important;
}
.staff-add-payroll-body-section-input-field{
    width: 95%;
}
.staff-add-payroll-body-section-bottom-ctn{
    padding: 2% 0;
    width: 95%;
}
.staff-add-payroll-body-section-bottom-ctn .global-single-input{
    width: 100%!important;
    margin: 3% 0 !important;
}
.staff-add-payroll-body-section-input-two .global-single-input{
    margin: 1%!important;
    width: 46%!important;
}
.payroll-body-section-bottom-ctn-extra{
    padding: 2% 0% 2% 1%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.payroll-body-section-bottom-ctn-extra .global-single-input{
    width: 40%;
    margin-right: 0;
}
.payroll-body-section-bottom-ctn-extra input,.staff-add-payroll-body-section-footer input{
    text-align: right;
    padding: 2% 20%;
}
.payroll-body-section-bottom-ctn-extra p{
    margin: 0 0 6px 0;
    font-size: 0.8rem;
    font-weight: 600;
}
.staff-add-payroll-body-section-footer{
    padding: 2% 2% 2% 10%;
    display: flex;
    background-color: #e7e7e7;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
}
.staff-add-payroll-body-section-footer .global-single-input{
    width: 30%;
    margin-right: 0;
    background-color: white;
    border-radius: 5px;
}
.staff-add-payroll-body-section-footer .global-single-input input{
    background-color: #fff;
    height: 20px;
    width: 80%;
}
.staff-add-payroll-body-section-footer p{
    font-weight: 600;
    font-size: 0.8rem;
}
.staff-add-payroll-body-section-input-two{
    display: flex;
    justify-content: space-between;
}


.staff-add-payroll-footer-ctn{
    background-color: #fff;
    margin: 2% 0;
    padding: 3%;
    display: flex;
    justify-content: flex-end;
}
.staff-add-payroll-footer-ctn-section{
    background-color: #edeef0;
    width: 66%;
    padding: 1% 2%;
}

.staff-add-payroll-footer-ctn-left-section{
    flex-direction: row !important;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 2% 1%;
}
.staff-add-payroll-footer-ctn-left-section input{
    width: 40%;
    background-color: #fff;
    text-align: right;
    padding: 2% 5%;
}

.staff-add-payroll-footer-ctn-section .global-single-input{
    width:98%!important
}
.staff-add-payroll-footer-ctn-section .global-single-input input{
    width:25%!important;
    background-color: #fff;
}
.staff-add-payroll-footer-ctn-right{
    width: 40%;
    text-align: right;
}
.staff-add-payroll-footer-ctn-right h4{
    margin: 1%;
}
.staff-add-payroll-footer-ctn-right p{
    margin: 1%;
}
.staff-add-payroll-button-ctn{
    display: flex;
    justify-content: flex-end;
    margin: 4% 0;
}
