.client-view-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
}
.client-view-head{
    margin: 2% 0;
    padding: 2% 0;
    width: 90%;
    border-bottom: 1px solid #00000047;
}
.client-view-body{
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: 2% 0;
}
.client-view-body-section-three{
    display: flex;
}
.client-view-body-section-two{
    display: flex;
}
.client-view-button{
    width: 90%;
    display: flex;
    margin: 2% 0;
    justify-content: center;
}
.client-view-button-ctn{
    display: flex;
    justify-content: center;
    width: 30%;
}
.client-view-button button.submit-btn{
    background-color: blue;
    color: #fff;
    width: 75%;
    width: 75%;
    border: none;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.client-view-button button.cancel-btn{
    background-color: #fff;
    color: #000;
    width: 75%;
    width: 75%;
    border: 2px solid #000;
    font-size: 1rem;
    font-weight: 800;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}