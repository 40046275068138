.add-document-container {
    width: 70%;
    margin: 1% 0;
  }
  .add-document-search-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 1% 0 4% 0;
  }
  .add-document-search-container input {
    height: 40px;
    width: 40%;
    border-radius: 4px;
    border: 1px solid rgb(211, 210, 210);
    padding: 0 1%;
    font-size: 0.9rem;
  }
  .add-document-search-container input::placeholder {
    color: rgb(196, 196, 196);
    font-size: 0.8rem;
  }
  .add-document-search-container .add-document-search-icon {
    position: absolute;
    background-color: #eee;
    border-radius: 0px;
    height: 30px;
    width: 5%;
    top: 13%;
    right: 1%;
  }
  .add-document-documents-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add-document-documents-btn button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    font-size: 0.7rem;
  }
  
  /* add document dialog css */
  .add-document-dialog-container {
    width: 45vw;
    padding: 4%;
  }
  .add-document-dialog-input-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .add-document-dialog-input-container .add-document-input {
    width: 48%;
    position: relative;
  }
  .add-document-dialog-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8% 0 2% 0;
  }
  
  
  /* dialog document-view-container */
  
  .document-view-container{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2% 3%;
    width: 40vw;
  }
  .document-view-container-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .document-view-body-section{
    display: flex;
    flex-direction: column;
    width: 48%;
  }
  .document-view-img-container{
    width: 100%;
    min-height: 24vh;
    display: flex;
    flex-direction: column;
  } 
  .document-view-img-container img{
    width: 100%;
    height: 200px;
  }
  .document-view-container-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3% 0;
  }
  .document-view-img-container-body{
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .document-view-img-container-body img{
    width: 170px;
    height: 170px;
  }
  
  .document-print-view{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20%;
  }
  .document-print-view img{
    width: 100%;
    height: auto;
  }
  
  .menu-item-document-ul{
    display: flex;
    flex-direction: row;
    padding-inline-start: 10px;
    padding-inline-end: 15px;
    align-items: center;
  }
  .menu-item-document-li{
    display: flex;
    flex-direction: column;
  }
  .menu-item-document-ul >:nth-child(1){
    min-width: 160px;
    padding: 10px;
  }
  .document-add-doc-dialog{
    color: blue;
    font-size: 0.7rem !important;
    align-self: flex-end;
    cursor: pointer;
  }