.customer-support-container h4{
    width: fit-content;
    margin:1% 4px;
}
.customer-support-status{
    display: flex;
    flex-direction:row;
    background-color:#2fa757;
    justify-content: center;
    align-items: center;
}
.customer-support-status p{
    color:#fff;
    font-size:0.8rem;
}