.emp-bank-detail-container{
    margin: 1%;
    background-color: #fff;
    padding: 3%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}
.emp-bank-detail-input-section{
    display: flex;
    flex-wrap: wrap;
    width: 65%;
    justify-content: space-between;
}
.emp-bank-detail-input-section .global-single-input,.emp-company-info-section-body .global-single-input{
    width: 45%;
}
.emp-company-info-section{
    margin: 0 1%;
    background-color: #fff;
    padding: 2% 3%;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-bottom: 0;
}
.emp-company-info-section-body{
    display: flex;
    width: 65%;
    justify-content: space-between;
    margin: 1% 0;
}
.emp-company-info-section-bottom{
    display: flex;
    justify-content: space-between;
    width: 30%;
    align-items: center;
}
.emp-company-info-status-switch-container{
    display: flex;
    align-items: center;
}
.account-login-section{
    display: flex;
    flex-direction: column;
    margin: 2% 0;
}
.account-login-section .global-single-input{
    flex-direction: row;
    margin-left: 0;
    align-items: center;;
    gap: 20%;
    width: 60%;
}
.account-login-section .global-single-input p{
    font-size: 0.9rem;
    font-weight: 500;
}