.bottom-left-container{
 flex:55%;
 background-color: #fff;
 display: flex;
 flex-direction: column;

}
.bottom-right-container{
    flex:45%;
    margin:0 0 0 1%;
}
.bottom-left-first-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:4%
}
.bottom-left-second-container{
    /* display: flex;
    width: 100%;
    margin: 6% 4%;
    position: relative;
    max-height: 40vh;
    height: 40vh;
    padding: 24px;
    height:300px; */
    padding:0 1% 4% 1%

}
.bottom-left-second-container canvas{
    height:190px !important;
}

.sales-radio-btn-grp{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content:space-around;
    width:33%;
    height:32px;
    background-color: #8373f1;
    border-radius:4px;
}
.sales-radio-btn-grp input[type="radio"]{
  display:none;
}
.sales-radio-btn-grp label{
   color:#fff;
   /* border-right:1px solid #fff; */
   cursor:pointer;
   font-size: 0.9rem;
   padding-right: 8px;
   width: 25%;
   height: 70%;
   /* display: flex;
   align-items: center;
   justify-content: center; */
   border-radius:4px;
    margin:1% 3%; 
}

.sales-radio-btn-grp input[type="radio"]:checked + label{
   background-color:#7d60ca;
}
.sales-radio-btn-grp hr{
    transform: rotate(180deg);
    height:20px;
    border: none;
    border-right:1px solid #fff !important;
   
}
/* .{
    background-color:#fff ;
} */


/* purchase revenue card */
.purchase-revenue-card-container{
    height:90px;
    display: flex;
    justify-content:center;
    align-items:center;
 
}
.purchase-revenue-card-content{
    display: flex;
    flex-direction:row;
    width:80%;
    justify-content: space-between;


}
.purchase-revenue-card-icon-container{
    background-color: #fff;
    width:25px;
    height:25px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.purchase-revenue-card-content p{
    font-size: 1rem;
    font-weight:600
}