.total-revenue-footer{
    display: flex;
    width: 100%;
    background-color: #a6a7af;
    justify-content: flex-end;
    margin-top: 3%;

}
.total-revenue-footer-ctn{
    display: flex;
    flex-direction: column;
    width: 35%;
    margin: 2% 4%;
}
.total-revenue-footer-ctn-top{
    margin: 2% 0;
    display: flex;
    justify-content: space-between;
}
.total-revenue-footer-ctn-top input{
    margin: 5% 2%;
    border: none;
}
.total-revenue-footer-ctn-top-left{
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 0.9rem;
    justify-content: space-around;
    color: #fff;
    width: 45%;
    font-weight: 500;
}
.total-revenue-footer-ctn-top-left p{
    margin: 5% ;
}

.total-revenue-footer-ctn-top-right{
    display: flex;
    flex-direction: column;
    width: 50%;
    
}
.total-revenue-footer-ctn-bottom{
    display: flex;
    justify-content: space-between;
}
.total-revenue-footer-ctn-bottom-right{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.total-revenue-footer-ctn-bottom input{
    margin: 5% 2%;
    height: 4vh;
    border: none;
}
.total-revenue-footer-ctn-bottom-left{
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 0.9rem;
    width: 45%;
    justify-content: space-around;
    color: #fff;
    font-weight: 700;
}
.total-revenue-footer-ctn-bottom-left p{
    margin: 5% ;
}