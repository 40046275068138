.dashboard-parent-container {
  box-sizing: border-box;
  display: flex;
  position: fixed;
  height: 100vh;
}
.dashboard-parent-container .dashboard-left-menu-container {
  flex: 18;
  animation: flex 0.02ms ease-out;
  /* width: 30%; */
}
.dashboard-parent-container .dashboard-right-division {
  flex: 82;
  animation: flex 0.02ms ease-in;
  background-color: #eef1f9;
  /* width: 70%; */
}
.dashboard-pages-container {
    height: 92vh;
    overflow: auto;
}
.hide {
  display: none;
}
