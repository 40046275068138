/* leave application */
.leave-application-autocomplete div.MuiOutlinedInput-root{
    background-color: #ff5100 !important;
}
.leave-application-autocomplete input{
    background-color: #ff5100 !important;
}
.leave-status-approved{
    background-color: #b8deb1;
    color: #00bb0f;
    padding: 4% 12%;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 600;
}
.leave-status-rejected{
    background-color: #deb1b1;
    color: #c60000;
    padding: 4% 12%;
    border-radius: 12px;
    font-size: 0.8rem;
    font-weight: 600;
}
.add-leave-content-view{
    border: 1px solid #636060;
    padding: 1%;
    min-height: 10vh;
}
.add-leave-content-view p{
    font-size: 0.9rem;
    margin: 0;
}
.add-leave-view-button{
    display: flex;
    justify-content: flex-end;
    margin: 4% 6% 1% 0;
}

/* add leave Type  */
.add-leave-type-popup-container{
    width: 30vw;
}
.add-leave-type-popup-sub-container{
    padding: 4%;
    display: flex;
    flex-direction: column;
}
.add-leave-type-popup-input-container{
    display: flex;
    flex-wrap: wrap;
}
.leave-type-input{
    width: 98% !important;
}
.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6% 0 0 0;
}