p.active{
    background-color:#3dd100;
    color: #fff;
    padding: 4px 8px;
    border-radius: 16px;
}
p.inactiveuser{
    background-color:#b90000;
    color: #fff;
    padding: 4px 8px;
    border-radius: 16px;
}
p.inactive{
    background-color:#ee7700;
    color: #fff;
    padding: 4px 8px;
    border-radius: 16px;
}
.registerd-user-parent-container h3 ,.tax-list-table-container h3{ 
    margin: 1% auto 1% 1%;
    width: fit-content;
}
.registerd-user-parent-container hr{
    border: none;
    border-bottom: 1px solid #eee;
}
.registerd-user-form-container{
    display: flex;
    justify-content: space-between;
    margin: 1%;
}
.registerd-user-form-child{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.registerd-user-form-child-two{
    display: flex;
    justify-content: space-between;

}
.registerd-user-cancel-submit-container{
    margin: 3% 0;
}
.registerd-user-button-contained-black-outlined{
    width: 15%;
    height: 42px;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    color: #000;
    font-weight: 600;
    margin: 1%;
}

.global-single-input.fullwidth-input input[type="date"]::-webkit-inner-spin-button,
.global-single-input.fullwidth-input input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    text-align: center;
}

