.home-dashboard-main-container{
    display: flex;
    flex-direction: column;
}
.home-dashboard-top-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:0 1%;
}
.date-time-container{
    display: flex;
    flex-direction: row;
    margin: 0 4%;
}
.home-dashboard-second-container{
    display: flex;
    flex-direction: row;
    margin:1%;
    /* width: 100%; */
    justify-content: space-between;
}
.home-dashboard-middle-container{
    display:flex;
    flex-direction:row;
    margin: 1%;
}
.home-dashboard-bottom-container{
    display:flex;
    flex-direction:row;
    margin: 1%;
}
