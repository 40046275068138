.offers-listed-topbar-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.offers-listed-topbar-container h3{
    margin-right: auto;
    width: fit-content;
}
.offers-listed-table table tbody tr td img{
    width: 48px;
}

/* Dialog  */
.offers-listed-dialog-parent{
    width: 60vw;
}
.offers-listed-dialog-wrapper{
    padding: 2%;
}
.offers-listed-dialog-parent h3{
    margin: 1%;
}
.offers-listed-dialog-parent hr{
    border: none;
    margin: 1% auto;
    border-bottom: 1px solid #eee;
}
.offer-listed-form-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.offers-listed-view-input{
    width: 40% !important;
}
.offers-listed-view-input img{
    width: 35%;
}
.offers-listed-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.offers-listed-button-container .cancel-btn{
    margin: 2%;
}