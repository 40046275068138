.catelog-header-container{
    background-color: #fff;
    margin: 1%;
    padding: 1% 2%;
    display: flex;

}
.catelog-header-container p{
    margin: 0 8px;
    font-size: 0.9rem;
    font-weight: 600;
}
.catelog-heading-button{
    display: flex;
    justify-content: space-between;
    margin: 1%;
    align-items: center;
}
.catelog-heading-button p{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
  }
.catelog-buttom-container{
    display: flex;
    width: 40%;
    justify-content:end;
    height: 44px;

}
.catelog-buttom-container .MuiOutlinedInput-root{
    height: 40px;
}
.create-category-container{
    width: 40vw;
   
}
.create-category-icon-container{
    padding: 1%;
}
.save-iocn{
    color: blue;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 2%;
    cursor: pointer;
}
.close-icon{
    color: red;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 2%;
}
.create-category-name{
    margin: 1% 0;
    font-size: 0.8rem;
    font-weight: 600;
}
.create-category-input-field{
    display: flex;
    /* justify-content: space-between; */
    margin: 4% 0;
    flex-wrap: wrap;
}
.new-global-single-input .MuiFormLabel-root.Mui-focused {
    color: #000;
    font-size: 1rem;
    
  }
  .new-global-single-input
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #bebebe !important;
  
}
.new-global-single-input div.MuiOutlinedInput-root {
    width: 100%;
    border: none;
    height: 40px;
  }
  .new-global-single-input .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 100%;
  }
  .new-global-single-input {
    width: 22%;
    margin: 8px;
   
  }
  .new-global-single-input
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  height: 0px;
}
  .catelog-category-list{
    display: flex;
    flex-wrap: wrap;

  }
.catelog-category-list-single{
    width: 125px;
    margin: 1%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.catelog-category-list-single img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #000;
}
.catelog-category-list-single p{
    margin: 1% ;
    font-size: 0.9rem;
    font-weight: 600;
}

.catalog-button{
    width: 15%;
    height: 40px;
    margin: 0 1%;
    cursor: pointer;
    color: #fff;
    font-size: 0.9rem;
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.export{
    background-color: #f02100;
}
.import{
    background-color:  #f06800 ;
}
.create{
    background-color: blue;
}
.catalog-table-container{
    max-height: 65vh;
    overflow-y: auto;
    margin: 1%;
}
.catalog-table-container table{
  width: 100%;
  border-collapse: collapse;
}
.catalog-table-container table thead tr th{
    position: sticky;
    bottom: 0;
    background-color: #fff;
    height: 50px;
    z-index: 2;
    color: #000;
  }
 .catalog-table-container table tbody  {
    min-height: 50px;
  }
  .catalog-table-container table tbody tr td {
   
 
    height: 100%;
    text-align: center;
    min-height: 40px;
    height: 40px;
    font-size: 0.8rem;
  }
.add-model{
    font-size: 0.8rem;
    font-weight: 600;
    color: blue;
    text-align: end;
    margin: 8px ;
    cursor: pointer;

}

/* global product table */
.global-product-table {
    /* padding: 1%; */
    background-color: #fff;
    max-height: 26vh;
    overflow-y: auto;
    /* min-height: 30vh; */
  }
  .global-product-table table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
  }
  .global-product-table table thead {
    position: sticky;
    top: -6px;
    background-color: #dee3f8;
    height: 30px;
    z-index: 2;
  }
  .global-product-table table thead tr th {
    height: 100%;
    text-align: center;
    font-size: 0.8rem;
  }
  .global-product-table table tbody {
    min-height: 36px;
  }
  .global-product-table table tbody tr td {
    border: 1px solid #000;
    height: 100%;
    text-align: center;
    min-height: 40px;
    /* height: 30px; */
    font-size: 0.8rem;
  }
  .global-product-table table tbody tr td input {
    height: 100%;
    width: 100%;
    border: none;
  }
  .global-product-table::-webkit-scrollbar {
    width: 4px;
    background-color: #f1f1f1;
  }
  .global-product-table::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
  .global-product-table-input-icon{
    display: flex;
   
  }
  .global-product-table-input-icon img{
    width: 20px;
    cursor: pointer;
  }
  .special-items-table table tbody tr td{
    border: none;
    padding-top: 9px;
} 
.special-items-table table tbody tr td:hover{
    border: 2px solid #dee3f8;
} 

.special-items-table input{
    text-align: center;
    height: auto!important;
}
  