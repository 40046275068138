.auto-complete-violet .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
    background-color: #8373f1!important;
    height: 30px;
    cursor: pointer;
}
.auto-complete-violet input{
    background-color: #8373f1!important;
    color: #ffffff;
    height: 30px;
    cursor: pointer;
  
}

.auto-complete-violet input::placeholder{
    color: #ffffff!important;
    font-weight: 800;
}
.auto-complete-violet .css-i4bv87-MuiSvgIcon-root,.auto-complete-violet .css-ptiqhd-MuiSvgIcon-root{
    color: #ffffff;
}
.auto-complete-violet div.MuiOutlinedInput-root{
    height: 30px;
}
.auto-complete-violet .MuiAutocomplete-input{
    height: 12px;
    
  }
  .auto-complete-violet .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    padding: 0;
  }

.query-msg-dialog-container{
    width: 50vw;
}
.query-msg-dialog-section{
    padding: 4% 6%;
    display: flex;
    flex-direction: column;
}
.query-msg-dialog-section hr{
    margin: 3% 0;
}
.query-message-field{
    background-color: #edeef0;
    border-radius: 4px;
    min-height: 15vh;
    padding: 2% 3%;
}
.query-message-field p{
    line-height: 1.4rem;
    font-size: 0.9rem;
    color: #575757;
}
.query-msg-dialog-section button{
    align-self: center;
    margin-top: 5%;
    width: 20%;
    height: 36px;
}