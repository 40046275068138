.staff-dashboard-container{
    display: flex;
    flex-direction: column;
    margin: 1%;
    /* overflow-x: hidden; */
}
.staff-dashboard-body-container{
    display: flex;
    justify-content: space-between;
}
.staff-dashboard-total-expense-container{
    background-color: #fff;
    padding: 1%;
    width: 64%;
 
}
.staff-dashboard-total-expense-container .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.staff-dashboard-total-expense-container .top-bar p {
    font-weight: 600;
}
.staff-dashboard-total-expense-container .top-bar .custom-radio-group input[type="radio"]:checked + label {
    background-color: #f27474 !important;
}

.staff-dashboard-notice-board-container{
    background-color: #fff;
    padding: 1%;
    width: 30%;   
}
.staff-dashboard-notice-board-container-body{
    height: 25vw;
    overflow-y: scroll;
    overflow-x: hidden;
}
.staff-dashboard-notice-board-container-body::-webkit-scrollbar {
    width: 2px;
    
    /* background-color: #d3e3ec; */
    background-color: #f1f1f1;
  }
.staff-dashboard-notice-board-container-body::-webkit-scrollbar-thumb {
    /* background-color: #a7c6d8; */
    background-color: #c1c1c1;
    height: 20px;
  }

.staff-dashboard-notice-board-container-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1%;
}
.staff-dashboard-notice-board-container-top .btn-notice-board{
    background-color: #e64949;
    width: 30%;
    font-size: 0.9rem !important;
}
.staff-dashboard-notice-board-container-section{
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 6px;
    position: relative;
    background-color: #eef2f9;
    align-items: center;
    padding: 1%;
    margin-top: 20px;
}
.staff-dashboard-notice-board-container-section-left{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 2% 5%;
    background-color: #efc6c3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.staff-dashboard-notice-board-container-section-left p{
    margin: 0;
    color: red;
    font-size: 0.8rem;
    font-weight: 600;
}
.staff-dashboard-notice-board-container-section-right{
    width:60%;
}
.staff-dashboard-notice-board-container-section-right h4{
    margin: 0;
    font-size: 0.9rem;
}
.staff-dashboard-notice-board-container-section-right p{
    margin: 0;
    font-size: 0.6rem;
    font-weight: 600;
}
.staff-dashboard-body-container-two-right-notification-content h3{
    font-size: 1rem;
    margin: 1%;
    display: flex;
}
.staff-dashboard-body-container-two-right-notification-content p{
    font-size: 0.9rem;
    margin: 1%;
    display: flex;
}
.staff-dashboard-body-container-two{
    display: flex;
    justify-content: space-between;
    margin-top:1%;
}
.staff-dashboard-body-container-two-left{
    background-color: #fff;
    padding: 1%;
    width: 29%;
}
.staff-dashboard-body-container-two-right{
    background-color: #fff;
    width: 67%;


}

.chart-container-total-count{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    left: 41%;
}
.chart-container-total-count h4,.chart-container-total-count h1{
    margin: 0;
}
.staff-dashboard-doughnut{
    position: relative;
}
.staff-dashboard-body-container-two-right .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 3%;
   
   
}
.staff-dashboard-body-container-two-right .top-bar .custom-radio-group input[type="radio"]:checked + label {
    background-color: #ac0000 !important;
}


.staff-dashboard-body-container-two-right-notification{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.staff-dashboard-body-container-two-right-notification-left{
    content: "";
    height: 9vh;
    width: 8px;
    background-color: #e64949;
}
.staff-dashboard-body-container-two-right-notification-content{
    width: 83%;
}

.staff-dashboard-body-container-two-right-notification-right{
    margin-right: 3%;
}
.staff-dashboard-body-container-two-right-notification-right p{
    font-weight: 600;

}

.staff-dashboard-body-container-bottom{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin:2% 0;
}
.staff-dashboard-body-container-bottom .top-bar{
    display: flex;
    width: 96.5%;
    align-items: center;
    justify-content: space-between;
    padding: 1%;
    /* border: 2px solid orange; */
}
.staff-dashboard-body-container-bottom .top-bar .custom-radio-group input[type="radio"]:checked + label {
    background-color: #ac0000 !important;
}

.btn-green-outlined{
    background-color: #70aa6a;
    color:#40a534;
    font-weight: 600;
    width: 75%;
    font-size: 0.75rem;
    height: 30px !important;
  }



  /* DialogBOxes */
  /* DialogBOxes */
  /* DialogBOxes */


  .notice-add-board-container{
    width: 30vw;
    display: flex;
    flex-direction: column;
    padding: 2% 4%;
  }

  .notice-add-board-input{
    width: 100%;
  }
  .notice-add-board-input input{
    background-color: #eef2f9;
  }
  .notice-add-board-input textarea{
    resize: none;
    background-color: #eef2f9;
    height: 80px;
  }

  .notice-add-board-container-section{
    display: flex;
    justify-content:space-between;
  }

  .notice-add-board-container-section .notice-add-board-input{
    width: 45%;
  }
  .notice-add-board-container-section-two {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 60%;
  }
  .notice-add-board-container-section-two .notice-add-board-input{
    width: 32%;
  }

  .staff-dashboard-notice-board-container-section-delete-btn{
    position: absolute;
  }






  /* -----------*/
  .emp-dashboard-apply-leave-btn {
    margin: 1% 1% 1% 0 !important;
  }
  .employee-dashborad-top-container {
    display: flex;
    justify-content: flex-end;
  }
  .employee-dashboard-date-time-picker {
    width: 32%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
  }
  .employee-dashboard-date-picker {
    background-color: #ff5050;
    display: flex;
    height: 30px;
    border-radius: 4px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 1%;
    width: 47%;
  }
  .employee-dashboard-date-picker .calendar-icon {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 500;
    position: absolute;
    left: 4px;
  }
  .employee-dashboard-date-picker input {
    border: none;
    background-color: #ff5050;
    color: #fff;
    padding: 0 2%;
    cursor: pointer;
    flex-direction: row-reverse;
  }
  
  .date-picker-hr {
    border-right: 1px solid #fff !important;
    border: none;
    height: 20px;
    padding: 0 2%;
  }
  
  .employee-dashboard-time-picker {
    background-color: #2611e2;
    display: flex;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 1%;
    width: 47%;
  }
  .employee-dashboard-time-picker input {
    border: none;
    background-color: #2611e2;
    color: #fff;
    padding: 0 2%;
    cursor: pointer;
    flex-direction: row-reverse;
  }
  /* .employee-dashboard-date-picker input[type="date"] {
    background-color: #ff5050;
    border: none;
    height: 40px;
    width: 45%;
    flex-direction: row-reverse;
    color: #fff;
    border-radius: 4px;
    margin: 1%;
    font-size: 1rem;
  }
  .employee-dashboard-date-picker input[type="time"] {
    background-color: #2611e2;
    border: none;
    height: 40px;
    width: 45%;
    flex-direction: row-reverse;
    color: #fff;
    border-radius: 4px;
    margin: 1%;
    font-size: 1rem;
  } */
  
  .employee-dashboard-card-container {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
  }
  .employee-dashboard-card-container >:nth-child(3){
  margin-right: 0;
  }
  .employee-dashboard-card {
    background-color: #fff;
    padding: 2% 3%;
    width: 32%;
    margin: 1% 2% 1% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
   
  }
  .employee-dashboard-card-icon-container {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    background-color: #0020f1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .employee-dashboard-card-text-container {
    width: 47%;
  }
  .employee-dashboard-card-text-container h5 {
    margin: 1%;
  }
  .employee-dashboard-card-text-container p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .employee-dashboard-card-arrow-icon-container {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #0020f1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .employee-dashboard-graph-calender-container {
    display: flex;
    justify-content: space-between;
  }
  .employee-dashboard-graph-container {
    width: 64%;
    background-color: #fff;
    padding: 2%;
    height: 55vh;
    max-height: 55vh;
    border-radius: 4px;
  }
  .employee-dashboard-calender-container {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 55vh;
  }
  .employee-dashboard-calender-top-container {
    background-color: #fff;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .employee-dashboard-calender-top-container h4 {
    margin: 0;
  }
  .react-calendar {
    border: none !important;
    width: inherit !important;
    height: auto;
    padding: 0 2%;
  }
  .employee-dashboard-calender-second-container {
    height: 44vh;
  }
  .leave-holidays-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 1% 0;
  }
  .leave-details-container {
    background-color: #fff;
    padding: 2%;
    width: 56%;
    display: flex;
    flex-direction: column;
  }
  
  .leave-details-top-container {
    display: flex;
    justify-content: space-between;
  }
  .leave-details-top-container h3 {
    margin: 1% 2%;
  }
  .leave-details-top-right-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
  }
  .leave-details-top-right-child-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .leave-details-top-right-child-container h5 {
    color: #159115;
    margin: 1% 0;
  }
  .leave-details-top-right-child-container p {
    color: #159115;
    margin: 2% 0;
    font-size: 1.2rem;
  }
  .leave-details-table-container table {
    width: 98%;
    margin: 2%;
  }
  .leave-details-table-container table thead tr th {
    padding: 16px 4px;
    font-weight: 600;
    font-size: 0.9rem;
    text-align: left;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .leave-details-table-container table tbody tr td {
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 4px 6px;
  }
  .leave-details-table-container table tbody tr td.leave-table-balance {
    color: rgb(172, 169, 169);
  }
  .leave-details-table-container table tbody tr td.left-head {
    font-size: 0.8rem;
    font-weight: 700;
  }
  .dashboard-apply-leave-btn {
    align-self: center;
    margin: 1% 0 0 0 !important;
  }
  .holiday-container {
    background-color: #fff;
    padding: 2% 4% 0 4%;
    width: 31%;
  }
  .holiday-container h3 {
    margin: 1%;
  }
  .holiday-content-container {
    margin: 4% 0;
  }
  .holiday-content-container-single-container {
    display: flex;
    margin: 2% 0;
  }
  .holiday-single-container-box {
    width: 55px;
    height: 55px;
    background-color: #e0e0ff;
    color: #3e16ff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .holiday-single-container-box .date {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .holiday-single-container-box .month {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .holiday-single-container-middle-text {
    display: flex;
    flex-direction: column;
    width: 58%;
    margin: 0 0 0 8%;
    justify-content: center;
  }
  .holiday-single-container-middle-text h4 {
    margin: 0;
    font-size: 0.9rem;
  }
  .holiday-single-container-middle-text p {
    margin: 0;
    font-size: 0.8rem;
  }
  .holiday-single-container-last-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .holiday-single-container-last-text p {
    margin: 0;
    font-size: 0.8rem;
    font-size: 0.7rem;
    font-weight: 600;
  }
  
  .employee-dashboard-card-icon-container-circle{
    border: 1px solid #fff;
    border-radius: 50%;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  
  /* / EMpDashboardTotalCommission CSS /
  / EMpDashboardTotalCommission CSS /
  / EMpDashboardTotalCommission CSS / */
  
  .employee-dashboard-total-commission-container{
    
    display: flex;
    width: 100%;
    justify-content: space-between;
  
  }
  .employee-dashboard-total-commission-container a{
    text-decoration: none;
    width: 16%;
    border: none;
  }
  .employee-dashboard-total-commission-container a:active{
    border: none;
  }
  .employee-dashboard-total-commission-container .total-commission{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  
  }
  .employee-dashboard-total-commission-container .total-commission:hover{
   
    border: 1px solid rgb(78, 163, 237);
    color: rgb(76, 163, 239);
  
  }
  .employee-dashboard-total-commission-container .total-commission i{
    font-size: 2rem;
  }
  .btn-font-size-less{
    font-size: 0.9rem !important;
  }

