.add-new-loan-single-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2% 0;
}
.add-new-loan-single-container h4{
    margin-left: 8px;
}
.add-new-loan-single-input-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin:1% 0;
}
.image-upload-file-conatiner{
    display: flex;
    width: 80px;
    height: 80px;
}
.image-upload-file-conatiner input{
    display: none;
}
.image-upload-file-conatiner label{
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.image-upload-file-conatiner label img{
    width: 100%;
    height: 100%;
}

.image-preview{
    display: flex;
    width: 80px;
    height: 80px;
    position: relative;
}
.image-preview img{
    width: 100%;
    height: 100%;
}
.image-close-icon{
    position: absolute !important;
    bottom: 80%;
    left: 82%;
    color: red !important;
    border: 1px solid red !important;
    width: 22px;
    height: 22px;
}