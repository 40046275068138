.floor-plan-approval-container h3{
    width: fit-content;
    margin:1% auto 2% 1%;
}

.floor-plan-approval-container table tr td .table-img-container{
    width:48px;
    height: 48px;
    margin: auto !important;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
}
.floor-plan-approval-container table tr td .table-img-container img{
    width: 100%;
    border-radius: 4px;
}
.floor-plan-approval-container table tr td .table-img-container img.download-icon{
    position: absolute;
    width: 40%;
    top: 0;
    right: 0;
}

.floor-plan-approval-container table tr td .table-upload-image{
    width: 100px;
    /* height: 32px; */
    margin: auto !important;
    cursor: pointer;
}
.floor-plan-approval-container table tr td .table-upload-image input[type='file']{
    display: none;
}
.floor-plan-approval-container table tr td .table-upload-image label{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #8373f1;
    padding: 6px 4px ;
    border-radius: 20px;
    cursor: pointer;
}
.floor-plan-approval-container table tr td .table-upload-image label p{
    color: #fff;
    font-size: 0.7rem;
    height: fit-content;
}