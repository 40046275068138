.topbar-parent-container{
    width:99.5%; 
    margin-left:8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 7%;
    background-color: #fff;
    /* margin-bottom: 1%; */
}

.topbar-parent-container .logout-container{
    display: flex;
    align-items: center;
    margin: auto 1%;
    background-color: #eee;
    padding: 1% 8px; 
    border-radius: 28px;
    height:0;
    cursor: pointer;
}   
.topbar-parent-container .logout-container p{
    font-size: 0.9rem;
    font-weight: 600;
    /* margin-left: 1%; */
}
/* Search input Component  */

.search-input-parent-container{
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    width: 20%;
    padding-left: 1%;
}

.search-input-parent-container input{
    border: none;
    height: 30px;
    width: 80%;
    font-size: 1rem;
}
.search-input-parent-container input:focus{
    outline: none;
}