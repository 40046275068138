.button-container-head{
    display: flex;
    width: 50%;
    justify-content: flex-end;
  }
  .button-container-head button{
    width:45%!important;
    font-size:0.8rem!important
  }
  .dashboard-employee-leave-details-search-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 28%;
    /* margin: 0 1%; */
  }
  .dashboard-employee-leave-details-search-container input {
    background-color: #eee;
    border: none;
    height: 40px;
    padding: 0 2%;
    width: 68%;
    font-size: 0.9rem;
  }
  .dashboard-employee-leave-details-search-container input::placeholder {
    font-size: 0.8rem;
  }
  .dashboard-employee-leave-details-search-container button {
    background-color: #000;
    color: #fff;
    height: 40px;
    width: 30%;
    cursor: pointer;
    border: none;
  }
/* dialog container */
.payroll-payslip-container-body-top{
    padding: 15px 0px;
}
.payroll-payslip-container{
    width: 60vw;
    background-color: #fff;
}
.payroll-payslip-container-header{
    display: flex;
    padding: 1% 5%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(166, 166, 166, 0.8);
}
.payroll-payslip-container-header-right{
    display: flex;
    width: 25%;
    align-items: center;
}
.payroll-payslip-container-header-right-close{
    font-size: 1.5rem;
    color: rgb(100, 100, 100) !important;
    cursor: pointer;
    background-color: transparent;
}
.payroll-payslip-container-header-right-close:hover{
    background-color:  rgb(100, 100, 100);
    color: #fff!important;
}
.payroll-payslip-container-header-right .btn-payslip-red{
    background-color: rgb(252 204 204);
    width: 45%;
}
.payroll-payslip-container-header-right .btn-payslip-blue{
    width: 45%;
    background-color: rgb(204 207 255);
}

.payroll-payslip-container-body{
    padding: 1% 5%;
}
.payroll-payslip-container-body-top-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9rem;
    font-weight: 400;
    margin: 1% 0;
}
.payroll-payslip-container-body-table{
    border: 1px solid rgb(229 229 229);
}


 .payroll-payslip-container-body-table{
    display: flex;
    flex-direction: column;
    
}
.payroll-payslip-container-body-table-container{
    display: flex;
    width: 100%;
}
.payroll-payslip-container-body-table-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(229 229 229);
    border-left: none;
}
.payroll-payslip-container-body-table-section-top{
    padding: 1% 5%;
}
.payroll-payslip-container-body-table-section-body{
    display: flex;
    width: 100%;
    height: auto;
}
.payroll-payslip-container-body-table-section-body-left{
    width: 65%;
    border-right: 1px solid rgb(229 229 229);
}
.payroll-payslip-container-body-table-section-body-right{
    width: 35%; 
}
.payroll-payslip-container-body-table-section-body-left-top,.payroll-payslip-container-body-table-section-body-right-top{
    padding: 10px 15px;
    border: 1px solid rgb(229 229 229);
    border-left: none;
    border-right: none;
}
.payroll-payslip-container-body-table-section-body-left-total,.payroll-payslip-container-body-table-section-body-right-total{
    border-top: 1px solid rgb(229 229 229);
    padding: 5px 15px;
}
.payroll-payslip-container-body-table-section-body-left-body,.payroll-payslip-container-body-table-section-body-right-body{
    padding: 0px 15px;
    min-height: 32vh;
}
.payroll-payslip-container-body-table-section-body-right-body h5,.payroll-payslip-container-body-table-section-body-left-body h5{
    margin: 10px 0px;
    font-size: 0.9rem;
    font-weight: 400;
}

.payroll-payslip-container-body-total{
    display: flex;
    margin: 2% 0;
    border: 1px solid rgb(229 229 229);
    width: 100%;
    padding: 2% 0%;
}
.payroll-payslip-container-body-total-section{
    padding: 0 2%;
    width: 20%;
}
.payroll-payslip-container-body-total-section p{
    font-size: 0.8rem;
    margin-top: 0;
    font-weight: 600;
    color: #7f7f7f;
}

.payroll-payslip-container-body-footer{
    width: 100%;
    text-align: center;
    border: 1px solid rgb(229 229 229);
    margin-bottom: 2%;
    padding: 2% 0%;
}
.payroll-payslip-container-body-footer p{
    font-size: 0.8rem;
    font-weight: 600;
}