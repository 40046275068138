.add-loan-dialog-box{
    width: 60vw;
    background-color: #fff;
}
.add-loan-dialog-container{
    padding: 5% 3%;
}

.add-loan-dialog-section{
    display: flex;
    flex-direction: column;
}
.add-loan-dialog-input-section{
    display: flex;
    flex-wrap: wrap;
    margin: 3% 0;
}
.add-loan-dialog-input-section .global-single-input{
    width: 31%;
}