.roles-and-permission-container{
   display: flex;
  
}
.roles-and-permission-single-container{
    background-color: #fff;
    padding: 2%;
    margin: 1%;
    width: 40%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
}
.roles-and-permission-single-container h3{
    margin: 0 auto 0 0;
}
.roles-and-permission-search-container{
    display: flex;
    margin: 2% 0;
    justify-content: center;
    align-items: center;
}
.roles-and-permission-search-container input{
    width: 98%;
    height: 30px;
    border: 1px solid #d7d4d4;
    padding: 1%;
    
}
.search-icon-container-roles-and-permission {
   background-color: #eee !important;
   border-radius: 1px !important;
   width: 41px;
    /* margin: 1% !important; */
    cursor: pointer;
    height: 40px;
}
.employee-list-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.employee-radio-button{
    width: 16%;
    display: flex;
    justify-content: space-between;
    margin: 1%;
}
.employee-radio-button input{
    accent-color: #8373f1;
    cursor: pointer;
}
.employee-radio-button label{
    color: #8373f1;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 600;
}
.roles-permissiom-right-top-container{
    display: flex;
}
.module-access-update-button{
    background-color: blue;
    border: none;
    width: 20%;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    height: 32px;
    margin: 0 0 1% 0;
}
.roles-and-permission-toggle-btn-container{
    padding: 1% 3%;
}
.single-toggle-button{
    display: flex;
    justify-content: space-between;
    margin: 4% 2%;
}
.single-toggle-button p{
    font-size: 0.9rem;
    font-weight: 500;
}
.toggle-checkbox{
    transition: 0.4s;
}
input.toggle-checkbox{
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 40px;
  height: 20px;
  background-color: #ce001c;
  border: 1px solid #be0019;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #ffa1ae;
  cursor: pointer;
}
input.toggle-checkbox:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    background: transparent;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  }
  input.toggle-checkbox:checked {
    background-color: #00ce67;
    box-shadow: inset 20px 0 0 0 #b1fbd6;
    border-color: #2acd7c;
  }
  input.toggle-checkbox:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
  }
  