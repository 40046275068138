/* Global Container  */
.global-container{
    margin: 8px;
    background-color: #fff;
    padding: 1%;
}
.global-heading-containe{
  display: flex;
  justify-content: space-between;
}
.global-top-container{
  text-align: start;
  padding: 2%;
  display: flex;
  flex-direction: column;
}
.global-top-container-head-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}
.global-top-container-filter-section{
  display: flex;
  margin: 3% 0;
  flex-wrap: wrap;
}
.global-top-container button{
  align-self: center;
}
.button-container{
  display: flex;
  width: 100%;
  justify-content: center;
}
/* .button-container button{
  width:45%!important;
  font-size:0.8rem!important
} */
/* Table css  */
.global-container-table {
  margin-top: 1%;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 2%;
}

.global-container-table table {
  width: 100%;
  margin-top: 1%;
  border-collapse: collapse;
}
.global-container-table::-webkit-scrollbar{
    width: 2.5px ;
}
.global-container-table::-webkit-scrollbar-thumb{
    background: #edf2f9;
}

.global-container-table table thead tr th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  font-size: 0.84rem;
  font-weight: 500;
  height: 30px;
}

.global-container-table table tbody tr td {
  text-align: center;
  background-color: #eee;
  font-size: 0.8rem;
  padding: 12px 12px;
  border-bottom: 8px solid #fff;
}

/* Buttons Css */
.btn {
  width: 70%;
  height: 40px;
  cursor: pointer;
  font-size: 0.8;
  margin: 8px;
  border-radius: 4px;
  border: none;
  color: #fff;
}
.disabled{
    background-color: #eee !important;
    color: #b9b9b9 !important;
    cursor: not-allowed !important;
}
/* blue contained button */
.global-button-contained-blue{
    width: 15%;
    height: 35px;
    background-color: #8373f1;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    color: #fff;
    margin: 1%;
}
.global-button-contained-blue:hover{
    background-color:#715ee9;
}

.global-button-contained-blue-royal{
  width: 13%;
  height: 35px;
  background-color: #2b0dee;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  margin: 1%;
}
.global-button-contained-blue-royal:hover{
  background-color:#4529f8;
}

/* black contained button  */
.global-button-contained-black{
    width: 15%;
    height: 35px;
    background-color: #000;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    color: #fff;
}
.global-button-contained-black:hover{
    background-color:rgb(11, 26, 18);
}

/* black outlined button  */
.global-button-contained-black-outlined{
    width: 15%;
    height: 35px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    color: #000;
    margin: 1%;
}
.global-button-contained-black-outlined:hover{
    background-color:rgb(231, 242, 247)
}
.btn-yellow{
  background-color:rgb(217 168 69)
}
.btn-yellow:hover{
  background-color:rgb(111, 89, 43)
}

/** global radio button **/
.global-radio-button {
  width: 35%;
}
.global-radio-button input[type="radio"] {
  accent-color: #000;
  margin: 0 4%;
  cursor: pointer;
}
.global-radio-button label {
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  /* margin: 0 4%; */
}
/* Inputs  */
/* input:focus{
    outline: none;
  }
.global-single-input{
    width: 23%;
    margin: 1%;
}
.global-single-input div.MuiOutlinedInput-root{
    width: 100%;
    border: none;
    background-color: #eee;
    height: 40px;
}
.global-single-input div fieldset{
    border: none;
    border-radius: 0;
}
.global-single-input p{
    margin:0 0 6px 0;
    font-size: 0.8rem;
    font-weight: 600;
    width: fit-content;
    margin-right: auto;
}
.global-single-input input{
    width: 94%;
    border: none;
    background-color: #eee;
    font-size: 1rem;
    height: 24px;
    margin-top: 1px;
    padding: 8px;
    border-radius: 4px;
}
.global-single-input input:disabled{
    cursor: no-drop;
    color: rgb(156, 156, 156);
}
.global-single-input input:focus,.global-single-input textarea{
    outline: none;
}
.global-single-input textarea{
    width: 100%;
    height: 12vh;
    background-color: #eee;
    border: none;
    font-size: 1rem;
    border-radius: 4px;
}
.auto-complete-cart div.MuiOutlinedInput-root{
    height: auto !important;
  }
  .auto-complete-cart .MuiAutocomplete-input{
    height: 7px;
  }
  .global-single-input
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 8px !important;
}
.global-single-input div fieldset {
  border: none;
  border-radius: 0;
} */

input:focus {
    outline: none;
  }
.global-single-input {
    position: relative;
    width: 23%;
    margin: 1%;
    display: flex;
    flex-direction: column;
  }
  .global-single-input p {
    margin: 0 auto 6px 0;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .global-single-input input {
    width: 95%;
    border: none;
    background-color: #eee;
    font-size: 1rem;
    height: 30px;
    padding: 8px;
    border-radius: 4px;
  }
  .global-single-input div.MuiOutlinedInput-root {
    width: 100%;
    border: none;
    background-color: #eee;
    height: 45px;
  }
  .global-single-input div fieldset {
    border: none;
    border-radius: 0;
  }
  .auto-complete div.MuiOutlinedInput-root{
    height: auto !important;
  }
  .auto-complete .MuiAutocomplete-input{
    height: 12px;
  }
  .global-single-input
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 8px !important;
  }
  .global-single-input input:disabled {
    cursor: no-drop;
    color: rgb(156, 156, 156);
  }
  .global-single-input input:focus,
  .global-single-input textarea {
    outline: none;
    resize: none;
  }
  .global-single-input textarea {
    width: 95%;
    padding: 8px;
    /* height: 12vh; */
    background-color: #eee;
    border: none;
    font-size: 1rem;
    border-radius: 4px;
  }
  .global-single-input input::placeholder{
    font-size: 0.9rem !important;
  }
  .global-single-input textarea::placeholder{
    font-size: 0.9rem !important;
  }

/****Sweetalert button styling***/

.swal2-actions button.swal2-cancel{
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    font-weight: 600;
}
.swal2-actions button.swal2-confirm{
    background-color: #002bff;
}
.customer-view-table{
    padding: 2% !important;
}

/* Brudcrump */
.breadcrumb{
    font-size: 0.9rem;
    font-weight: 600;

 }

 .edit-icon {
  color: #0045da;
  font-size: 18px;
}

.delete-icon {
  color: #db0000;
  font-size: 20px;
}
.visibility-icon {
  color: #5ba329;
  font-size: 1.3rem;
}
.search-icon{
  font-size: 1.3rem;
}
.download-icon {
  color: #f19800;
  font-size: 1.4rem;
}

/* customer list status */
.status-appproved{
    background-color: #06b900;
    padding: 4% 17%;
    border-radius: 12px;
    color: #fff;
    font-size: 0.75rem;
}
.status-registered{
  background-color:#8373f1;
  padding: 4% 14%;
  border-radius: 12px;
  color: #fff;
  font-size: 0.75rem;
}
.status-rejected{
  background-color: #ff0000;
  padding: 4% 19%;
  border-radius: 12px;
  color: #fff;
  font-size: 0.75rem;
}

/* global search container */
.global-search-container{
  display: flex;
  position: relative;
  width: 25%;
  box-shadow: 0 2px 4px rgb(209, 204, 204);
}
.global-search-container input{
  border: 1px solid #eee;
  border-radius: 4px;
  height: 30px;
  width: 100%;
  padding: 1% 2%;
}
.global-search-icon{
  position: absolute;
  right: 3%;
  top: 17%;
  font-size: 1.1rem;
}
.global-hr{
  border: none;
  border-bottom: 1px solid #8a8787;
  margin: 1%;
}

.validation-alert{
  color:red;
  font-size: 0.7rem !important;
  text-align: end;
  position: absolute;
  top: 100%;
  right: 0%;
}
.global-single-input-no input[type="number"]::-webkit-inner-spin-button{
  -webkit-appearance: none;
}




.trial-period-input input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;
 

}

.actions-select-dropdown-dialog {
  display: flex;
  flex-direction: column;
  /* width: 20vw; */
  padding: 3% 5%;
}
.actions-select-dropdown-dialog button {
  width: 70px;
  height: 25px;
}
.file-picker-new {
  width: 200px;
  height: 40px;
  border: 2.5px dashed #a7a2a2;
  margin: 30px 0 0 22px;
  border-radius: 4px;
}
.file-picker-new input {
  display: none;
}
.file-picker-new label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
}
.file-picker-new label i {
  width: 9%;
}
.justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-active{
  border: 1px solid #03af03;
  color: #01a301;
  padding: 4px;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 600;
}
.status-pakg-expired{
  border: 1px solid #f39f03;
  color: #f39f03;
  padding: 4px;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 600;
}
.status-pakg-refund{
  border: 1px solid #e707c2;
  color: #e904c3;
  padding: 4px;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 600;
}

.status-expired{
  border: 1px solid #ff1606;
    color: #fa1b0b;
    padding: 4px;
    margin: 0 !important;
}
.status-purchase{
    padding: 5px;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(238, 108, 2);
    color: #fff;
    font-size: 0.77rem;
    border-radius: 4px;
}
.new-global-table-container {
  padding: 1%;
  background-color: #fff;
  max-height: 75vh;
  overflow-y: auto;
}
.new-global-table-container::-webkit-scrollbar {
  width: 4px;
  /* background-color: #d3e3ec; */
  background-color: #f1f1f1;
}
.new-global-table-container::-webkit-scrollbar-thumb {
  /* background-color: #a7c6d8; */
  background-color: #c1c1c1;
}
.new-global-table-container table {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
}
.new-global-table-container table thead {
  position: sticky;
  top: -6px;
  background-color: #eef1f9;
  height: 50px;
  z-index: 2;
}
.new-global-table-container table thead tr th {
  height: 100%;
  text-align: center;
  font-size: 0.75rem;
}
.new-global-table-container table tbody {
  min-height: 36px;
}

.new-global-table-container table tbody tr td {
  border-bottom: 4px solid #edf2f9;
  height: 100%;
  text-align: center;
  min-height: 40px;
  height: 50px;
  font-size: 0.8rem;
  cursor: pointer;
}
.global-single-input1 {
  display: flex;

  margin: 1px;
  /* margin-bottom: 21px; */
  align-items: self-end;
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 20px;
  justify-content: flex-start;
  align-items: center;
}
.global-single-input1 .TaxCreateCheckBox {
  width: 1.2rem;
  height: 1.2rem;
  margin: 10px;
}
.product-table-auto-complete
  .MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  height: fit-content;
  width: 5px;
  display: flex;
  min-width: 50px;
}
.product-table-auto-complete .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.toggle-checkbox {
  /* margin: 16.8px 0; */
  /* -webkit-transition: .8s; */
  transition: 0.4s;
}
input.toggle-checkbox {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 40px;
  height: 20px;
  background-color: #ce001c;
  border: 1px solid #be0019;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #ffa1ae;
  cursor: pointer;
}

input.toggle-checkbox:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  background: transparent;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.toggle-checkbox:checked {
  background-color: #00ce67;
  box-shadow: inset 20px 0 0 0 #b1fbd6;
  border-color: #2acd7c;
}

input.toggle-checkbox:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
