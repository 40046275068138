.company-profile-top-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c7c5c5;
}
.company-profile-top-left-container{
    display: flex;
    width: 50%;
}
.company-profile-top-right-container{
    background-color: #d3f1d3;
    width: 5%;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2% 0 0;
    cursor: pointer;
}
.company-profile-top-right-container i{
    color: green;
    font-size:1.3rem;
}
.company-profile-single-tab{

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: fit-content;
    margin: 0 4%;
    height: 36px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #c4c4c4;
    cursor: pointer;
    padding: 0 3%;
}

.selected{
color: blue;
border-bottom: 6px solid blue;
border-bottom-left-radius: 6px;
border-bottom-right-radius: 6px;
}
.company-profile-bottom-container{
    display: flex;
    padding: 1%;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.company-info-input{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
}
.company-info-input p{
    font-size: 0.75rem;
    font-weight: 500;
    margin: 2% 0;
}
.company-info-input input{
    width: 99%;
    height: 30px;
    border-radius: 4px;
    border: 1px solid #8d8d8d;
  
}
.company-info-input textarea{
    width: 99%;
    border-radius: 4px;
    border: 1px solid #8d8d8d;
   
}
.company-info-input textarea:focus { 
    outline: none !important;
    border-color: #8d8d8d;
   
 }
.company-info-input div.MuiOutlinedInput-root {
    width: 100%;
    height: 35px;
    border: none;
  }
  .company-info-input .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
    height: 20px;
    border-color: #fff;
    
  }
  .company-info-input .MuiAutocomplete-root .MuiOutlinedInput-root{
    padding: 0;
  }
  .company-info-input .MuiAutocomplete-root {
    border-radius: 4px;
  }
  .company-info-input .MuiAutocomplete-root fieldset{
    border-color: #8d8d8d;
}
.company-info-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #8d8d8d; /* Border color when focused */
  }

.logo-browse-input{
    position: relative;
}
.logo-browse-container input{
    display: none;
}
.logo-browse-container label{
    color: #fff;
    padding: 1% 10%;
    font-size: 0.8rem;
    cursor: pointer;
}
.logo-browse-container{
    position: absolute;
    bottom: 10%;
    right: 4%;
    width: 16%;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
}
 
 
