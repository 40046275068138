.add-employee-container{
    display: flex;
    flex-direction: column;
    margin: 1%;
}
.add-employee-top-container{
    background-color: #fff;
    border-radius: 8px;
    padding: 1%;
    margin: 1% 0;
    width: 98%;
    height: 10%;
}
.add-employee-top-sub-container{
    border-radius: 4px;
    width: 100%;
    height: 90%;
    border-bottom: 1px solid rgb(138, 138, 138);
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    
}
.add-employee-single-tab{
    padding: 16px;
    cursor: pointer;
}
.add-employee-single-tab p{
  font-size: 0.9rem;
  font-weight: 600;
 
}
.tab-active{
    color: blue;
    border-bottom: 4px solid blue;
    border-radius: 4px;
}
.disable-menu{
    cursor: not-allowed;

}
.disable-menu p{
   color: #edeaea;
    
}

/************ personal info style **********/
.add-employee-profile-info-container{
    background-color: #fff;
    padding: 1%;
    border-radius: 4px;
}
.profile-info-sub-container{
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 2%
}
.add-employee-input{
    width: 38% !important;
    margin: 1% 4% 1% 1% !important;
}
.personal-info-account-login{
    background-color: orangered;
    border: none;
    height: 40px;
    width: 50%;
    border-radius: 4px;
    color: #fff;
    margin: auto auto 0 0;
}
.profile-info-button-container{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 0;
    padding: 0 2%;
}
.profile-info-button-container button{
    width: 24%  !important;
}
.account-login-top-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.account-login-top-container h3{
    margin: 2% 0;
}
.account-login-input{
    width:100% !important;
    margin: 16% 0 !important;
}
.account-login-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 8% 0;
}
.account-login-button button{
    width:30%
}
.account-login-password-input{
   width: 98% !important;
   margin: 4% 0 !important;
}
.toggle-status-container{
    width: 44%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1%;
}
.toggle-status-container p{
    color: blue;
    font-size: 0.9rem;
}
.company-info-generate-qr-barcode{
    background-color: blue;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: #fff;
    width: 70%;
    margin: 0 0 0 auto;
}

/* add location css */
.add-loaction-dialog-container{
    display: flex;
    flex-direction: column;
}
.add-loaction-dialog-top-container{
    background-color: #eee;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 0;
}
.add-loaction-dialog-top-container input{
    border: 1px solid #c9c6c6;
    height: 34px;
    width: 81%;
    padding: 1% 2%;
}
.add-loaction-dialog-top-container button{
    background: #000;
    color: #fff;
    width: 12%;
    height: 39px;
    font-size: 1rem;
    cursor: pointer;
}
.add-loaction-dialog-bottom-container{
    background-color: #eee;
    padding: 2%;
    margin: 3% 0;
}
.add-location-list{
    display: flex;
    flex-direction: column;
    padding: 2%;
}
.add-location-list p{
    font-size: 0.9rem;
    font-weight: 600;
}
.add-location-dialog-top-main-container{
 background-color: #eee;
}