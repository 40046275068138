.package-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40vw;
}
.package-head-container{
    border-bottom: 1px solid #00000047;
    color: black;
    width: 90%;
    padding: 2% 0;
    font-size: 0.7rem;
}


.package-body-container{
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: 2% 0;
}
.package-body-container-left{
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.package-body-container-right{
    display: flex;
    align-items: flex-end;
    width: 25%;
    justify-content: center;
    margin: 1% 0;
}
.package-body-container-right button{
    background-color: black;
    color: #fff;
    width: 75%;
    width: 75%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    height: 43px;
}
.package-container-table{
    display: flex;
    width: 96%;
    justify-content: center;
    margin: 1% 0;
}

.package-container-table table{
    display: flex;
    flex-direction: column;
    width: 92%;
}

.package-container-table table thead{
    border-bottom: 2px solid #b8b8b8;
}
.package-container-table td,.package-container-table th{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 20%; */
}
.package-container-table tr{
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
}

.package-container-button{
    width: 90%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}
.package-container-button-ctn{
    display: flex;
    justify-content: center;
    width: 30%;
}
.package-container-button-ctn button.submit-btn{
    background-color: black;
    color: #fff;
    width: 75%;
    width: 75%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.package-container-button-ctn button.cancel-btn{
    background-color: #fff;
    color: #000;
    width: 75%;
    width: 75%;
    border: 2px solid #000;
    font-size: 1rem;
    font-weight: 800;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
