.refund-transaction{
    height: 100vh;
    overflow-y: auto;
}

.refund-transaction-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
}
.refund-transaction-head-container{
    display: flex;
    flex-direction: column;
    color: black;
    width: 90%;
    height: 23vh;
    padding: 2% 0;
}
.refund-transaction-head-container-top{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #00000047;
    color: black;
    width: 100%;
    padding: 2% 0;
    font-size: 0.8rem;
}
.refund-transaction-head-container-bottom{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px dashed #00000047;
    color: black;
    width: 100%;
    padding: 2% 0;
    font-size: 0.8rem;
}
.refund-transaction-head-container h3{
    color: blue;
    font-weight: 800;
}

.refund-transaction-body-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: space-between;
    margin: 2% 0;
}
.refund-transaction-body-container-top{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.refund-transaction-body-container-bottom{
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin: 1% 0;
    
}
.refund-transaction-container-button{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}
.refund-transaction-container-button-ctn{
    display: flex;
    justify-content: center;
    width: 30%;
}
.refund-transaction-container-button-ctn button.submit-btn{
    background-color: #8373f1;
    color: #fff;
    width: 75%;
    border: none;
    margin: 1px 0;
    padding: 8px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
}
.refund-transaction-container-button-ctn button.submit-btn:hover{
    background-color: #715ee9;
}
.refund-transaction-container-button-ctn button.cancel-btn{
    background-color: #fff;
    color: #000;
    width: 75%;
    width: 75%;
    border: 2px solid #000;
    font-size: 1rem;
    font-weight: 800;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.refund-transaction-container-button-ctn button.cancel-btn:hover{
    background-color: rgba(255, 0, 0, 0.363);
    color: rgb(255, 255, 255);
    border: 2px solid #fff;
    font-weight: 500;
}
.refund-transaction-body-payment-section{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 6%;
}
.refund-transaction-body-payment-section-top{
    margin: 2% 1% ;
    font-size: 1rem;
    font-weight: 600;
}
.refund-transaction-body-payment-section-top input{
    width: 30%;
    height: 5vh;
    font-size: 1rem;
    padding: 8px;
    margin: 2% 0;
}
.refund-transaction-body-payment-section-bottom{
    display: flex;
}
.refund-transaction::-webkit-scrollbar{
    width: 7px;
}
.refund-transaction::-webkit-scrollbar-thumb{
    background: #715ee9; 
  border-radius: 50px;
}
.refund-transaction::-webkit-scrollbar-thumb:hover{
    background: #8373f1; 
}
.refund-transaction-body-payment-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    border-top: 1px dashed #00000047;
}