.card-container{
    background-color:#fff;
    padding:1%;
    width:30.5%;
    height:100px;
    border-radius:4px
   
    /* justify-content: center; */
}
.card-sub-container{
    display:flex;
    flex-direction: row;
    margin: 4%;
    /* justify-content: space-evenly; */
}
.card-icon-container{
    background-color:#8373f1;
    height:65px;
    width:65px;
    border-radius:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16%;
}
.user-image{
    height:35px;
    width:35px;
}
.card-content{
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
   
}
.card-content-head{
    font-size: 0.9rem;
    font-weight: 600;
}
.card-content-price{
    align-self:flex-start;
    margin-top: 8px;
}