.left-main-container{
    display:flex;
    flex-direction: column;
    
}
.left-logo{
    margin: 8% 4% 1% 4%;
}
.logo-image{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width:30%;
  /* height:80px; */
  margin: 0 12%;
}
.left-container-main-form{
    display: flex;
    flex-direction: column;
    margin:4%;
    /* justify-content: flex-start;
    align-items: left; */
}
.left-container-head{
    display: flex;
    margin: 7% 20%;
    flex-direction: column;
    justify-content:flex-start;
    align-items:flex-start;
}
.left-container-sub-form{
    display: flex;
    flex-direction: column;
    /* margin:0 12%; */
   

}
.left-container-sub-form-input{
    display:flex;
    flex-direction: column;
    margin: 2% 0;
    width: 100%;
    justify-content:center;
    align-items:center;
}
.left-container-sub-form-input p{
    font-size:0.9rem;
    font-weight: 500;
    display: flex;
    align-self:flex-start;
    position: relative;
    left:20%;
    padding-bottom: 8px;
}
.left-container-sub-form-input input{
    display: flex;
    border-radius: 25px;
    width:58%;
    height:44px;
    border:1px solid rgb(206, 201, 201);
    padding-left:24px;
}
.left-container-sub-form-input input::placeholder{
    margin-left: 8px;
}
.forgot-password{
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
}
.forgot-password p{
    display:flex;
    align-self: flex-end;
    position: relative;
    left:22%;
    /* margin: 0 0 0 8%; */
    font-size: 0.9rem;
    font-weight: 500;
}
.login-button{
    display: flex;
    justify-content: center;
    /* margin:0 12%; */
    width:100%
}
.login-button button{
    border-radius: 25px;
    width:63%;
    height:44px;
    background-color:#000;
    color:#fff;
    font-size:1rem;
    margin:4% 0;
    cursor: pointer;
}
.login-button button:hover{
    background-color: rgb(26, 26, 26);
}