.manage-employee-view-employee-top-container {
    background-color: #fff;
    padding: 2%;
    border-radius: 4px;
    display: flex;
  }
  .manage-employee-view-employee-top-left-container {
    width: 30%;
    border-right: 1px solid rgb(179, 176, 176);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
  }
  .manage-employee-view-employee-top-right-container {
    width: 70%;
  }
  .view-employee-employee-image {
    border-radius: 50%;
    /* width: 100px;
    height: 100px; */
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-employee-employee-image img {
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
  }
  .manage-employee-view-employee-top-left-container h4 {
    margin: 6% 0 2% 0;
  }
  .manage-employee-view-employee-top-left-container p {
    margin: 0;
  }
  .manage-employee-view-employee-top-right-container {
    display: flex;
    justify-content: space-around;
    padding: 2%;
  }
  .view-employee-top-right-sub-container {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1%;
  }
  .view-employee-top-right-sub-container h4 {
    margin: 3% 0;
  }
  .view-employee-top-right-attendence {
    background-color: #eef2f9;
    width: 95%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4%;
  }
  .view-employee-top-right-attendence h5 {
    color: #0055fe;
    margin: 2% 0;
  }
  .view-employee-top-right-attendence p {
    color: #1ebe69;
    margin: 3% 0;
    font-size: 1.3rem;
    font-weight: 600;
  }
  .view-employee-top-right-attendence span {
    color: #000;
  }
  
  /* tab */
  .view-employee-tab-container {
    margin: 1% 0;
    padding: 1% 0;
  }
  