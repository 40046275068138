.refund-report-view-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
}
.refund-report-view-head-container{
    border-bottom: 1px solid #00000047;
    color: black;
    width: 90%;
    padding: 2% 0;
    font-size: 0.7rem;
}


.refund-report-view-body-container{
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: 2% 0;
}
.refund-report-view-body-container-section{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
}


.refund-report-view-container-button{
    width: 90%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}
.refund-report-view-container-button-ctn{
    display: flex;
    justify-content: center;
    width: 30%;
}
.refund-report-view-container-button-ctn button.cancel-btn{
    background-color: black;
    color: #fff;
    width: 75%;
    width: 75%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
