.custom-date-parent{
    position: relative;
    margin:0  4%;
    background-color:#e0e5f9;
    border-radius: 4px;
    /* height:35px; */
}
.time-picker-input{
    display: flex;
    flex-direction: row-reverse;
    border:none;
    background-color:#e0e5f9;
    color: #2e58c9;
    height:35px;
    width:100%;
    font-weight:500;
    font-size:0.9rem;
    border-radius: 4px;
    cursor:not-allowed
    
}
.time-picker-input::-webkit-calendar-picker-indicator{
    appearance: none;
    width: 32px;
    opacity: 0;
    z-index: 10;
    cursor:not-allowed
}
