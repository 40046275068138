.device-settings-container p{
    width: fit-content;
    margin:1% 4%;
    font-weight: 500;
}
.device-settings-container hr{
    border: none;
    border-bottom: 1px solid rgb(222, 218, 218);
    margin:0 4%
}
.device-settings-form{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin:1%;
}

.device-settings-form .Country-validation-alert{
    color: red;
}

.device-settings-input{
    width:26% !important;
    margin:1.3% 3% !important;
}
.device-settings-cancel-submit-btn-container{
    padding:1% 0;
}

.provider-list-container,.refund-list-container{
    margin: 1%;
    background-color: #fff;
    padding: 1%;
}
.provider-list-container p{
    width: fit-content;
    margin:2% 4%;
    font-weight: 500;
}

.refund-list-container button.refund-pending-btn{
    background-color: blue;
    color: #fff;
    border: none;
    width: 95%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.refund-status-pending-ctn{
    height: 20px;
    font-size: 0.78rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: sandybrown;
    background-color: rgba(244, 165, 96, 0.185);
}
.refund-status-cancel-ctn{
    height: 20px;
    font-size: 0.78rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 0, 0);
    background-color: rgba(255, 0, 0, 0.308);
}
.refund-list-container button.refund-cancel-btn{
    background-color: blue;
    color: #fff;
    border: none;
    width: 95%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.refund-list-container button:hover{
    background-color: #0003d171;
}
.refund-list-container button:disabled{
    background-color: rgba(0, 4, 255, 0.253);
}