.package-module-parent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
}
.package-module-head-container{
    border-bottom: 1px solid #00000047;
    color: black;
    width: 90%;
    padding: 2% 0;
    font-size: 0.7rem;
}


.package-module-body-container{
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin: 2% 0;
    align-items: center;
}
.package-module-body-container-left{
    display: flex;
    justify-content: space-between;
    width: 75%;
}
.package-module-body-container-right{
    display: flex;
    align-items: flex-end;
    width: 17%;
    justify-content: center;
    margin: 1% 0;
}
.package-module-body-container-right button{
    background-color: black;
    color: #fff;
    width: 75%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
}
.add-module-duration-button{
    background-color: black;
    color: #fff;
    width: 15%;
    margin:3% 1% 1% 1%;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
}
.package-module-container-table{
    display: flex;
    width: 96%;
    justify-content: center;
    margin: 1% 0;
}

.package-module-container-table table{
    display: flex;
    flex-direction: column;
    width: 92%;
}

.package-module-container-table table thead{
    border-bottom: 2px solid #b8b8b8;
}
.package-module-container-table td,.package-module-container-table th{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 20%;
}
.package-module-container-table tr{
    display: flex;
    justify-content: space-around;
    margin: 2% 0;
}

.package-module-container-button{
    width: 90%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}
.package-module-container-button-ctn{
    display: flex;
    justify-content: center;
    width: 30%;
}
.package-module-container-button-ctn button.submit-btn{
    background-color: black;
    color: #fff;
    width: 75%;
    width: 75%;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}
.package-module-container-button-ctn button.cancel-btn{
    background-color: #fff;
    color: #000;
    width: 75%;
    width: 75%;
    border: 2px solid #000;
    font-size: 1rem;
    font-weight: 800;
    margin: 1px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
}

/* ::-webkit-scrollbar{
    width: 9px;
}
::-webkit-scrollbar-thumb{
    border-radius: 100vw;
    height: 20px;
    background-color: #004eff;
} */